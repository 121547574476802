import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

// component
import Helmet from "components/Helmet/Helmet";
import Footer from "components/layout/footer";

// style
import common from "assets/scss/common.module.scss";

//img
import cloud from "assets/images/img-cloud.svg";

const Layout = ({ children, setDirection }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>vp net</title>
      </Helmet>
      <main className={common["common-layout-right"]}>
        <div className={common["common-layout-right-inner"]}>
          {children}
          <Link
            to="/"
            onClick={() => {
              setDirection("toLeft");
            }}
            className={`${common["scroll-btn"]} ${common["scroll-btn--left"]}  ${common["common-layout-right-back"]}`}
          ></Link>
          <img
            className={common["common-layout-right-cloud"]}
            src={cloud}
            alt=""
          />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
