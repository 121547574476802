import { useState, useEffect, useCallback } from "react";

export default function useWindowSize() {
  const isSSR = typeof window !== "undefined";
  const initialSize = {
    width: isSSR ? window.innerWidth : undefined,
    height: isSSR ? window.innerHeight : undefined,
  };
  const [windowSize, setWindowSize] = useState(initialSize);

  const resizeHandler = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    // cleanup
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  return windowSize;
}
