import React from "react";
import { useTranslation } from "react-i18next";

//img
import bgUse from "assets/images/bg-use.svg";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/product.module.scss";

const Use = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`${common["wrapper"]} ${common["wrapper--white"]} ${style["product-use-wrapper"]}`}
    >
      <div className={`${common["wrapper-inner"]} ${style["product-use"]}`}>
        <div className={style["product-use-list-wrapper"]}>
          <h2
            className={`${common["box-title"]} ${common["box-title-black"]} ${style["product-use-title"]}`}
            dangerouslySetInnerHTML={{ __html: t("product_title_use") }}
          ></h2>
          <div className={style["product-use-list"]}>
            <div
              className={style["product-use-item"]}
              style={{
                backgroundImage: `url(${bgUse})`,
              }}
            >
              {t("product_use_dev")}
            </div>
            <div
              className={style["product-use-item"]}
              style={{
                backgroundImage: `url(${bgUse})`,
              }}
              dangerouslySetInnerHTML={{ __html: t("product_use_multi") }}
            ></div>
            <div
              className={style["product-use-item"]}
              style={{
                backgroundImage: `url(${bgUse})`,
              }}
              dangerouslySetInnerHTML={{ __html: t("product_use_hard") }}
            ></div>
            <div
              className={style["product-use-item"]}
              style={{
                backgroundImage: `url(${bgUse})`,
              }}
              dangerouslySetInnerHTML={{ __html: t("product_use_storage") }}
            ></div>
          </div>
        </div>

        <div className={style["product-use-text"]}>
          <div className={style["product-use-text-item"]}>
            <p>{t("product_use_text1")}</p>
          </div>
          <div className={style["product-use-text-item"]}>
            <p>{t("product_use_text2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Use;
