import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// util
import { validateEmail } from "components/utils/validation";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/order.module.scss";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CreateAccount = ({ setAccountEnabled, setAccountValues }) => {
  const { t } = useTranslation();

  // account creation
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [acceptTos, setAcceptTos] = useState(false);

  useEffect(() => {
    if (
      !validateEmail(email) ||
      !acceptTos ||
      !email.trim() ||
      !displayName.trim() ||
      !password.trim() ||
      password.trim() !== passwordRepeat.trim()
    ) {
      setAccountEnabled(false);
      setAccountValues(null);
      return;
    }

    setAccountEnabled(true);
    setAccountValues({
      Email: email,
      Password: password,
      client_id: "WEB",
      i_accept_the_terms_of_service: acceptTos,
      displayName: displayName,
    });
  }, [
    email,
    password,
    displayName,
    passwordRepeat,
    acceptTos,
    setAccountEnabled,
    setAccountValues,
  ]);

  return (
    <>
      <div className={style["order-create-account"]}>
        <h3 className={style["order-create-account-title"]}>
          {t("purchase_pay_create_title")}
        </h3>
        <div
          className={`${common["form-input"]} ${style["order-create-account-input"]}`}
        >
          <input
            type="text"
            onChange={(e) => {
              setDisplayName(e.target.value);
            }}
            value={displayName}
            placeholder={t("purchase_pay_create_name")}
          />
        </div>
        <div
          className={`${common["form-input"]} ${style["order-create-account-input"]}`}
        >
          <input
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            placeholder={t("purchase_pay_create_email")}
          />
        </div>
        <div
          className={`${common["form-input"]} ${style["order-create-account-input"]}`}
        >
          <input
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            placeholder={t("purchase_pay_create_password")}
          />
        </div>
        <div
          className={`${common["form-input"]} ${style["order-create-account-input"]}`}
        >
          <input
            type="password"
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
            }}
            value={passwordRepeat}
            placeholder={t("purchase_pay_create_password_confirm")}
          />
        </div>
        <div
          className={`${common["form-check"]} ${style["order-create-account-tos"]}`}
        >
          <input
            type="checkbox"
            id="tosAccept"
            value={acceptTos}
            onChange={(e) => {
              setAcceptTos(e.target.checked);
            }}
          />
          <label htmlFor="tosAccept" className={common["form-check-label"]}>
            <span
              className={`${common["form-check-label-text"]} ${style["order-create-account-tos-text"]}`}
              dangerouslySetInnerHTML={{
                __html: t("purchase_pay_create_tos", { link: "/aaaa" }),
              }}
            />
            <span className={common["form-check-label-icon"]}>
              <FontAwesomeIcon icon={faCheck} />
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
