import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Element } from "react-scroll";

// style
import common from "assets/scss/common.module.scss";
import index from "assets/scss/index.module.scss";

//img
import cloud from "assets/images/img-cloud.svg";
import benefitsExpress from "assets/images/icon-express-benefits.svg";
import benefitsDesktop from "assets/images/icon-desktop-benefits.svg";
import benefitsUpgrade from "assets/images/icon-upgrade-benefits.svg";
import benefitsSecurity from "assets/images/icon-security-benefits.svg";

const Benefits = () => {
  const { t } = useTranslation();
  const [benefitsToggle, setBenefitsToggle] = useState(null);

  const BenefitsToggleHandler = (num) => {
    if (num !== benefitsToggle) {
      setBenefitsToggle(num);
    } else {
      setBenefitsToggle(null);
    }
  };

  return (
    <Element name="benefit">
      <div
        className={`${common["wrapper"]} ${common["wrapper--white"]} ${index["benefits-wrapper"]}`}
      >
        <div className={`${common["wrapper-inner"]} ${index["benefits"]}`}>
          <h2 className={index["benefits-title"]}>{t("benefits_title")}</h2>

          <div className={index["benefits-list"]}>
            <div className={index["benefits-item"]}>
              <dl className={index["benefits-item-info"]}>
                <dt
                  className={classNames(index["benefits-item-info-title"], {
                    [index["benefits-item-info-title--active"]]:
                      benefitsToggle === 1,
                  })}
                  onClick={() => {
                    BenefitsToggleHandler(1);
                  }}
                >
                  <img
                    className={`${index["benefits-item-img"]} ${index["benefits-item-img--express"]}`}
                    src={benefitsExpress}
                    alt={t("benefits_express_title")}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("benefits_express_title"),
                    }}
                  ></span>
                </dt>
                <dd
                  className={classNames(index["benefits-item-info-text"], {
                    [index["benefits-item-info-text--active"]]:
                      benefitsToggle === 1,
                  })}
                >
                  {t("benefits_express_text")}
                </dd>
              </dl>
            </div>

            <div className={index["benefits-item"]}>
              <dl className={index["benefits-item-info"]}>
                <dt
                  className={classNames(index["benefits-item-info-title"], {
                    [index["benefits-item-info-title--active"]]:
                      benefitsToggle === 2,
                  })}
                  onClick={() => {
                    BenefitsToggleHandler(2);
                  }}
                >
                  <img
                    className={`${index["benefits-item-img"]} ${index["benefits-item-img--desktop"]}`}
                    src={benefitsDesktop}
                    alt={t("benefits_desktop_title")}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("benefits_desktop_title"),
                    }}
                  />
                </dt>
                <dd
                  className={classNames(index["benefits-item-info-text"], {
                    [index["benefits-item-info-text--active"]]:
                      benefitsToggle === 2,
                  })}
                >
                  {t("benefits_desktop_text")}
                </dd>
              </dl>
            </div>

            <div className={index["benefits-item"]}>
              <dl className={index["benefits-item-info"]}>
                <dt
                  className={classNames(index["benefits-item-info-title"], {
                    [index["benefits-item-info-title--active"]]:
                      benefitsToggle === 3,
                  })}
                  onClick={() => {
                    BenefitsToggleHandler(3);
                  }}
                >
                  <img
                    className={`${index["benefits-item-img"]} ${index["benefits-item-img--upgrade"]}`}
                    src={benefitsUpgrade}
                    alt={t("benefits_upgrade_title")}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("benefits_upgrade_title"),
                    }}
                  />
                </dt>
                <dd
                  className={classNames(index["benefits-item-info-text"], {
                    [index["benefits-item-info-text--active"]]:
                      benefitsToggle === 3,
                  })}
                >
                  {t("benefits_upgrade_text")}
                </dd>
              </dl>
            </div>

            <div className={index["benefits-item"]}>
              <dl className={index["benefits-item-info"]}>
                <dt
                  className={classNames(index["benefits-item-info-title"], {
                    [index["benefits-item-info-title--active"]]:
                      benefitsToggle === 4,
                  })}
                  onClick={() => {
                    BenefitsToggleHandler(4);
                  }}
                >
                  <img
                    className={`${index["benefits-item-img"]} ${index["benefits-item-img--security"]}`}
                    src={benefitsSecurity}
                    alt={t("benefits_security_title")}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("benefits_security_title"),
                    }}
                  />
                </dt>
                <dd
                  className={classNames(index["benefits-item-info-text"], {
                    [index["benefits-item-info-text--active"]]:
                      benefitsToggle === 4,
                  })}
                >
                  {t("benefits_security_text")}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <img
          className={`${index["benefits-cloud"]}`}
          src={cloud}
          alt={t("benefits_title")}
        />
      </div>
    </Element>
  );
};

export default Benefits;
