import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "store/store";
import classNames from "classnames";

// component
import Spec from "components/spec/spec";

//img
import bgSolutions from "assets/images/bg-solutions.png";
import cloud from "assets/images/img-cloud.svg";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/order.module.scss";

// constant
import {
  CATALOG_PRODUCT_SMALL,
  CATALOG_PRODUCT_MEDIUM,
  CATALOG_PRODUCT_LARGE,
  CATALOG_PRODUCT_CUSTOM,
} from "store/reducer";

const SelectPlan = ({ small, medium, large, custom }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);

  const changeNum = (e) => {
    dispatch({
      type: "CHANGHE_TERM",
      term: e.target.value,
    });
  };

  const changePlan = (product) => {
    dispatch({
      type: "SELECTED_DONE",
      selected: product,
    });
  };

  return (
    <>
      <div
        className={`${common["wrapper"]} ${common["wrapper--white"]} ${style["order-plan"]}`}
        style={{ backgroundImage: `url(${bgSolutions})` }}
      >
        <div
          className={`${common["wrapper-inner"]} ${style["order-plan-inner"]}`}
        >
          <img className={style["order-plan-cloud"]} src={cloud} alt="" />
          <h2
            className={`${common["box-title"]} ${style["order-select-title"]}`}
            dangerouslySetInnerHTML={{ __html: t("purchase_select_title") }}
          ></h2>

          <div className={style["order-select-wrapper"]}>
            <div className={style["order-select"]}>
              <dl className={style["order-select-list"]}>
                {small !== void 0 && (
                  <div
                    className={classNames(style["order-select-item"], {
                      [style["order-select-item--active"]]:
                        state.selected.Catalog_Product__ ===
                        CATALOG_PRODUCT_SMALL,
                    })}
                    onClick={() => changePlan(small)}
                  >
                    <dt className={style["order-select-item-name"]}>Small</dt>
                    <dd className={style["order-select-item-price"]}>
                      {small["Price.Price"].display}{" "}
                      {t("purchase_select_month")}
                    </dd>
                  </div>
                )}
                {medium !== void 0 && (
                  <div
                    className={classNames(style["order-select-item"], {
                      [style["order-select-item--active"]]:
                        state.selected.Catalog_Product__ ===
                        CATALOG_PRODUCT_MEDIUM,
                    })}
                    onClick={() => changePlan(medium)}
                  >
                    <dt className={style["order-select-item-name"]}>Medium</dt>
                    <dd className={style["order-select-item-price"]}>
                      {medium["Price.Price"].display}{" "}
                      {t("purchase_select_month")}
                    </dd>
                  </div>
                )}
                {large !== void 0 && (
                  <div
                    className={classNames(style["order-select-item"], {
                      [style["order-select-item--active"]]:
                        state.selected.Catalog_Product__ ===
                        CATALOG_PRODUCT_LARGE,
                    })}
                    onClick={() => changePlan(large)}
                  >
                    <dt className={style["order-select-item-name"]}>Large</dt>
                    <dd className={style["order-select-item-price"]}>
                      {large["Price.Price"].display}{" "}
                      {t("purchase_select_month")}
                    </dd>
                  </div>
                )}
                {custom !== void 0 && (
                  <div
                    className={classNames(style["order-select-item"], {
                      [style["order-select-item--active"]]:
                        state.selected.Catalog_Product__ ===
                        CATALOG_PRODUCT_CUSTOM,
                    })}
                    onClick={() => changePlan(custom)}
                  >
                    <dt className={style["order-select-item-name"]}>Custom</dt>
                    <dd className={style["order-select-item-price"]}>
                      {custom["Price.Price"].display}{" "}
                      {t("purchase_select_month")}
                    </dd>
                  </div>
                )}
              </dl>
              <div className={style["order-select-term"]}>
                <p className={style["order-select-term-text"]}>
                  {t("purchase_select_text")}
                </p>
                <div className={style["order-select-term-input-wrapper"]}>
                  <strong>Term</strong>
                  <input
                    type="number"
                    value={state.term}
                    onChange={changeNum}
                    min="1"
                    className={style["order-select-term-input"]}
                  />
                  Month
                </div>
              </div>
            </div>

            <Spec selectedPlan={state.selected} type="big" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPlan;
