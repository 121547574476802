import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// component
import StripeElements from "./stripeElemtnts";

const PaymentMethod = ({
  stripeKey,
  setStripeElements,
  setStripe,
  setStripeEnabled,
}) => {
  const [stripePromise, setStripePromise] = useState(null);

  // setStripePromise
  useEffect(() => {
    if (stripeKey === null) return false;
    setStripePromise(
      loadStripe(stripeKey.data.Fields.cc_token.attributes.key, {
        stripeAccount:
          stripeKey.data.Fields.cc_token.attributes.options.stripe_account,
      })
    );
  }, [stripeKey]);

  // stripe onChange
  const onStripeChange = (e) => {
    setStripeEnabled(e.complete === true);
  };

  if (!stripePromise) return false;

  return (
    <Elements stripe={stripePromise}>
      <StripeElements
        setStripeElements={setStripeElements}
        setStripe={setStripe}
        onChange={onStripeChange}
      />
    </Elements>
  );
};

export default PaymentMethod;
