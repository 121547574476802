import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";

// component
import { Grid, Input } from "@material-ui/core";
import Toast, { error } from "components/toast/toast";

// style
import common from "../../assets/scss/common.module.scss";
import style from "assets/scss/forgotten.module.scss";

const Form = ({ login, resetKey }) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [sendEnable, setSendEnable] = useState(false);
  const [sended, setSended] = useState(false);

  useEffect(() => {
    setSendEnable(
      newPasswordRepeat.trim() !== "" &&
        newPassword.trim() !== "" &&
        newPassword === newPasswordRepeat
    );
  }, [newPassword, newPasswordRepeat, setSendEnable]);

  const send = () => {
    const p = {
      login: login,
      key: resetKey,
      p1: newPassword,
      p2: newPasswordRepeat,
    };

    rest("User:recover_password", "POST", p)
      .then(() => {
        setSended(true);
      })
      .catch((response) => {
        error(response.message, false);
      });
  };

  return (
    <>
      <Toast duration={5000} />
      <Grid container spacing={8} direction="row">
        {sended && (
          <Grid item xs={12} md={12}>
            <div className={style["forgotten-success"]}>
              <p className={style["forgotten-success-title"]}>
                {t("password_reset_success_title")}
              </p>
              <p className={style["forgotten-success-text"]}>
                {t("password_reset_success_text")}
              </p>
            </div>
          </Grid>
        )}
        {!sended && (
          <>
            <Grid item xs={12} md={12}>
              <p
                className={`${common["common-label"]} ${style["forgotten-label"]}`}
              >
                {t("password_reset_label_new")}
              </p>
              <Input
                type="email"
                className={style["forgotten-input"]}
                required
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <p className={style["forgotten-text"]}>
                {t("password_reset_label_new_text")}
              </p>
            </Grid>
            <Grid item xs={12} md={12}>
              <p
                className={`${common["common-label"]} ${style["forgotten-label"]}`}
              >
                {t("password_reset_label_again")}
              </p>
              <Input
                type="email"
                className={style["forgotten-input"]}
                required
                onChange={(event) => setNewPasswordRepeat(event.target.value)}
              />
            </Grid>

            <Grid item alignItems="center" xs={12} sm={12}>
              <div className={style["forgotten-button-container"]}>
                <button
                  className={style["forgotten-button"]}
                  onClick={() => {
                    send();
                  }}
                  disabled={!sendEnable}
                >
                  {t("contact_send")}
                </button>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Form;
