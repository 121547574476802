import React from "react";
import { useTranslation } from "react-i18next";

// style
import style from "assets/scss/terms.module.scss";

// component
import Helmet from "components/Helmet/Helmet";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("terms_title")} | vp net</title>
      </Helmet>
      <div className={style["terms"]}>
        <h1 className={style["terms-title"]}>{t("terms_title")}</h1>
        <div className={style["terms-text"]}>
          <div className={style["terms-text-item"]}>
            <p dangerouslySetInnerHTML={{ __html: t("terms_text_left") }} />
          </div>
          <div className={style["terms-text-item"]}>
            <p dangerouslySetInnerHTML={{ __html: t("terms_text_right") }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
