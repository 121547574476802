import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { Link as HashLink } from "react-scroll"; // component
import useWindowSize from "components/utils/windowSize/windowSize";

// style
import common from "assets/scss/common.module.scss";

// img
import logo from "assets/images/logo.svg";
import cloud from "assets/images/img-cloud.svg";

export default function Footer() {
  const { t } = useTranslation();
  const size = useWindowSize();
  const location = useLocation();

  const typeOffset = {
    pc: {
      solution: -100,
      benefit: -300,
      region: -280,
      faq: -280,
    },
    sp: {
      solution: -103,
      benefit: -103,
      region: -103,
      faq: -103,
    },
  };

  const setOffset = (OffsetName) => {
    if (size.width > 960) {
      return typeOffset.pc[OffsetName];
    } else {
      return typeOffset.sp[OffsetName];
    }
  };

  return (
    <>
      <footer className={`${common["footer"]} ${common["wrapper"]}`}>
        <img src={cloud} alt="" className={common["footer-cloud"]} />
        <div className={`${common["wrapper-inner"]} ${common["footer-inner"]}`}>
          <div className={common["footer-info"]}>
            <p
              className={common["footer-info-text"]}
              dangerouslySetInnerHTML={{ __html: t("footer_text") }}
            />
            <ul className={common["footer-info-sns"]}>
              <li className={common["footer-info-sns-item"]}>
                <a className={common["footer-info-sns-item-link"]} href="/">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
              <li className={common["footer-info-sns-item"]}>
                <a className={common["footer-info-sns-item-link"]} href="/">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </li>
            </ul>
          </div>

          <div className={common["footer-menu"]}>
            <dl className={common["footer-menu-block"]}>
              <dt className={common["footer-menu-block-title"]}>
                {t("footer_title_vpnet")}
              </dt>
              <dd className={common["footer-menu-block-body"]}>
                <ul className={common["footer-menu-list"]}>
                  <li className={common["footer-menu-list-item"]}>
                    {location.pathname === "/" ? (
                      <HashLink
                        to="solution"
                        offset={setOffset("solution")}
                        smooth={true}
                        duration={300}
                      >
                        {t("footer_vpnet_solutions")}
                      </HashLink>
                    ) : (
                      <Link to="/#solution">{t("footer_vpnet_solutions")}</Link>
                    )}
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    {location.pathname === "/" ? (
                      <HashLink
                        to="benefit"
                        offset={setOffset("benefit")}
                        smooth={true}
                        duration={300}
                      >
                        {t("footer_vpnet_benefits")}
                      </HashLink>
                    ) : (
                      <Link to="/#benefit">{t("footer_vpnet_benefits")}</Link>
                    )}
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    {location.pathname === "/" ? (
                      <HashLink
                        to="region"
                        offset={setOffset("region")}
                        smooth={true}
                        duration={300}
                      >
                        {t("footer_vpnet_region")}
                      </HashLink>
                    ) : (
                      <Link to="/#region">{t("footer_vpnet_region")}</Link>
                    )}
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    {location.pathname === "/" ? (
                      <HashLink
                        to="faq"
                        offset={setOffset("faq")}
                        smooth={true}
                        duration={300}
                      >
                        {t("footer_vpnet_faq")}
                      </HashLink>
                    ) : (
                      <Link to="/#faq">{t("footer_vpnet_faq")}</Link>
                    )}
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/terms">{t("footer_vpnet_terms")}</Link>
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/blog">{t("footer_vpnet_blog")}</Link>
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/contact">{t("footer_vpnet_contact")}</Link>
                  </li>
                </ul>
              </dd>
            </dl>

            <dl className={common["footer-menu-block"]}>
              <dt className={common["footer-menu-block-title"]}>
                {t("footer_title_assistance")}
              </dt>
              <dd className={common["footer-menu-block-body"]}>
                <ul className={common["footer-menu-list"]}>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/">{t("footer_title_chat")}</Link>
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/">{t("footer_title_technical")}</Link>
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/">{t("footer_title_signal")}</Link>
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/terms">{t("footer_title_changelog")}</Link>
                  </li>
                  <li className={common["footer-menu-list-item"]}>
                    <Link to="/terms">{t("footer_title_networkmap")}</Link>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <p
            className={`${common["footer-bottom-text"]} ${common["footer-bottom-text--sp"]}`}
            dangerouslySetInnerHTML={{ __html: t("footer_bottom_text") }}
          />
          <div className={common["footer-logo"]}>
            <img src={logo} alt={t("head_title_logo")} />
          </div>
        </div>
        <p
          className={common["footer-bottom-text"]}
          dangerouslySetInnerHTML={{ __html: t("footer_bottom_text") }}
        />
      </footer>
    </>
  );
}
