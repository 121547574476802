import React, { useEffect, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

// style
import style from "assets/scss/order.module.scss";

// img
import bgCredit from "assets/images/bg-credit.png";

const StripeElements = ({ setStripeElements, setStripe, onChange }) => {
  const elements = useElements();
  const stripe = useStripe();

  const [numberComplete, setNumberComplete] = useState(false);
  const [expComplete, setExpComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);

  const cardOption = {
    style: {
      base: {
        fontSize: "10px",
        color: "#fff",
        padding: "20px",
        "::placeholder": {
          color: "#fff",
        },
      },
    },
  };

  useEffect(() => {
    setStripe(stripe);
    setStripeElements(elements);
  }, [setStripe, setStripeElements, stripe, elements]);

  useEffect(() => {
    onChange({ complete: numberComplete && expComplete && cvcComplete });
  }, [numberComplete, expComplete, cvcComplete, onChange]);

  const numberChangeHandler = (e) => {
    setNumberComplete(e.complete);
  };

  const expChangeHandler = (e) => {
    setExpComplete(e.complete);
  };

  const cvcChangeHandler = (e) => {
    setCvcComplete(e.complete);
  };

  return (
    <>
      <div
        className={style["order-create-info-card"]}
        style={{
          backgroundImage: `url(${bgCredit})`,
        }}
      >
        <div className={style["order-create-info-card-number"]}>
          <CardNumberElement
            onChange={numberChangeHandler}
            options={cardOption}
          />
        </div>
        <div className={style["order-create-info-card-expiry"]}>
          <CardExpiryElement onChange={expChangeHandler} options={cardOption} />
        </div>
        <div className={style["order-create-info-card-cvc"]}>
          <CardCvcElement onChange={cvcChangeHandler} options={cardOption} />
        </div>
      </div>
    </>
  );
};

export default StripeElements;
