import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Element } from "react-scroll";

// style
import common from "assets/scss/common.module.scss";
import index from "assets/scss/index.module.scss";

//img
import cloudFaq from "assets/images/img-cloud-faq.svg";
import bgFaq from "assets/images/bg-faq.png";

const Index = () => {
  const { t } = useTranslation();
  const [faqToggle, setFaqToggle] = useState(null);

  const faqToggleHandler = (num) => {
    if (num !== faqToggle) {
      setFaqToggle(num);
    } else {
      setFaqToggle(null);
    }
  };

  return (
    <Element name="faq">
      <div
        className={`${common["wrapper"]} ${common["wrapper--white"]} ${index["faq-wrapper"]}`}
        style={{ backgroundImage: `url(${bgFaq})` }}
      >
        <div className={`${common["wrapper-inner"]} ${index["faq"]}`}>
          <h2
            className={index["faq-title"]}
            dangerouslySetInnerHTML={{ __html: t("faq_title") }}
          ></h2>
          <div className={index["faq-list-wrapper"]}>
            <ol className={index["faq-list"]}>
              <li className={index["faq-item"]}>
                <dl className={index["faq-item-inner"]}>
                  <dt
                    className={classNames(index["faq-item-title"], {
                      [index["faq-item-title--active"]]: faqToggle === 1,
                    })}
                    onClick={() => {
                      faqToggleHandler(1);
                    }}
                  >
                    {t("faq_general_title")}
                  </dt>
                  <dd
                    className={classNames(index["faq-item-text"], {
                      [index["faq-item-text--active"]]: faqToggle === 1,
                    })}
                  >
                    {t("faq_general_text")}
                  </dd>
                </dl>
              </li>

              <li className={index["faq-item"]}>
                <dl className={index["faq-item-inner"]}>
                  <dt
                    className={classNames(index["faq-item-title"], {
                      [index["faq-item-title--active"]]: faqToggle === 2,
                    })}
                    onClick={() => {
                      faqToggleHandler(2);
                    }}
                  >
                    {t("faq_imghub_title")}
                  </dt>
                  <dd
                    className={classNames(index["faq-item-text"], {
                      [index["faq-item-text--active"]]: faqToggle === 2,
                    })}
                  >
                    {t("faq_imghub_text")}
                  </dd>
                </dl>
              </li>

              <li className={index["faq-item"]}>
                <dl className={index["faq-item-inner"]}>
                  <dt
                    className={classNames(index["faq-item-title"], {
                      [index["faq-item-title--active"]]: faqToggle === 3,
                    })}
                    onClick={() => {
                      faqToggleHandler(3);
                    }}
                  >
                    {t("faq_storage_title")}
                  </dt>
                  <dd
                    className={classNames(index["faq-item-text"], {
                      [index["faq-item-text--active"]]: faqToggle === 3,
                    })}
                  >
                    {t("faq_storage_text")}
                  </dd>
                </dl>
              </li>
            </ol>
            <ol className={index["faq-list"]}>
              <li className={index["faq-item"]}>
                <dl className={index["faq-item-inner"]}>
                  <dt
                    className={classNames(index["faq-item-title"], {
                      [index["faq-item-title--active"]]: faqToggle === 4,
                    })}
                    onClick={() => {
                      faqToggleHandler(4);
                    }}
                  >
                    {t("faq_backup_title")}
                  </dt>
                  <dd
                    className={classNames(index["faq-item-text"], {
                      [index["faq-item-text--active"]]: faqToggle === 4,
                    })}
                  >
                    {t("faq_backup_text")}
                  </dd>
                </dl>
              </li>
              <li className={index["faq-item"]}>
                <dl className={index["faq-item-inner"]}>
                  <dt
                    className={classNames(index["faq-item-title"], {
                      [index["faq-item-title--active"]]: faqToggle === 5,
                    })}
                    onClick={() => {
                      faqToggleHandler(5);
                    }}
                  >
                    {t("faq_linux_title")}
                  </dt>
                  <dd
                    className={classNames(index["faq-item-text"], {
                      [index["faq-item-text--active"]]: faqToggle === 5,
                    })}
                  >
                    {t("faq_linux_text")}
                  </dd>
                </dl>
              </li>

              <li className={index["faq-item"]}>
                <dl className={index["faq-item-inner"]}>
                  <dt
                    className={classNames(index["faq-item-title"], {
                      [index["faq-item-title--active"]]: faqToggle === 6,
                    })}
                    onClick={() => {
                      faqToggleHandler(6);
                    }}
                  >
                    {t("faq_private_title")}
                  </dt>
                  <dd
                    className={classNames(index["faq-item-text"], {
                      [index["faq-item-text--active"]]: faqToggle === 6,
                    })}
                  >
                    {t("faq_private_text")}
                  </dd>
                </dl>
              </li>
            </ol>
          </div>
        </div>
        <img className={`${index["faq-cloud"]}`} src={cloudFaq} alt="" />
      </div>
    </Element>
  );
};

export default Index;
