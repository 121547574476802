import React, { useState, useEffect } from "react";
import { rest, Get } from "@karpeleslab/klbfw";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Toast, { error } from "components/toast/toast";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// style
import common from "assets/scss/common.module.scss";
import login from "assets/scss/login.module.scss";

// component
import Helmet from "components/Helmet/Helmet";
import LoginLoading from "components/loading/login-loading";

//img
import bgLogin from "assets/images/bg-login.jpg";

const Login = ({ setDirection, transitionStatus }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [session, setSession] = useState(null);
  const [flow, updateFlow] = useState(null);
  const [user, setUser] = useRest("User:get");
  const [form, setForm] = useState({});

  const postHandller = (obj) => {
    rest("User:flow", "POST", obj).then(handleResponse).catch(handleError);
  };

  const handleResponse = (response) => {
    if (response.data.url) {
      window.location.href = response.data.url;
      return;
    }

    if (response.data.complete) {
      setUser({
        data: response.data.user,
      });
      return;
    }

    if (response.data.session) {
      setSession(response.data.session);
    }

    updateFlow(response);
  };

  const handleError = (response) => {
    error(response.message, false);
  };

  const submitTrigger = (action, event = null) => {
    if (event !== null) {
      event.preventDefault();
    }

    switch (action.type) {
      case "submit":
        postHandller({ ...form, session: session });
        return;
      case "post":
        postHandller({ session: session, ...action, type: undefined });
        return;
      case "reset":
        postHandller({});
        return;
      default:
        return null;
    }
  };

  const isRequired = (fieldName) => {
    return flow.data.req.includes(fieldName);
  };

  const formChange = (event) => {
    const array = {};
    array[event.target.getAttribute("name")] = event.target.value;

    setForm({
      ...form,
      ...array,
    });
  };

  useEffect(() => {
    if (Get("session")) {
      submitTrigger({ type: "post", session: Get("session") });
    }
    submitTrigger({ type: "post" });
    // eslint-disable-next-line
	}, []);

  const buildList = () => {
    return flow.data.fields.map((field, idx) => {
      const { type } = field;
      const fieldId = (field.name ?? 0) + idx;
      switch (type) {
        case "text":
        case "password":
        case "email":
        case "phone":
          return (
            <div
              className={`${common["form-input"]} ${login["login-form-input"]}`}
              key={fieldId}
            >
              <input
                type={field.type}
                required={isRequired(field.name)}
                onChange={formChange}
                placeholder={field.label}
                name={field.name}
              />
            </div>
          );
        case "label": {
          if (field.link) {
            return (
              <p key={fieldId} className={common["form-label"]}>
                <a href={field.link} key={fieldId}>
                  {field.label}
                </a>
              </p>
            );
          }

          const isOauthSeparationLabel =
            flow.data.fields.length > idx + 1 &&
            flow.data.fields[idx + 1].type === "oauth2";
          return (
            <p
              key={fieldId}
              className={classNames(common["form-label"], {
                [`${common["form-label--center"]} ${login["login-form-label-oauth"]}`]: isOauthSeparationLabel,
              })}
            >
              {field.label}
            </p>
          );
        }
        case "checkbox":
          return (
            <div className={common["form-check"]} key={fieldId}>
              <input
                type="checkbox"
                id={field.name}
                name={field.name}
                onChange={formChange}
              />

              <label
                htmlFor={field.name}
                className={common["form-check-label"]}
              >
                <span className={common["form-check-label-text"]}>
                  {field.label}
                </span>
                <span className={common["form-check-label-icon"]}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </label>
            </div>
          );
        default:
          return null;
      }
    });
  };

  /* Oauth */

  const buildOauthBtns = () => {
    return flow.data.fields
      .filter((field) => field.type === "oauth2")
      .map((field) => {
        return (
          <button
            variant="contained"
            key={field.id}
            className={common["form-oauth-item"]}
            style={{
              backgroundImage: `url(${field.info.Button_Style.logo})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "70% 70%",
              borderRadius: 50,
              backgroundColor: field.info.Button_Style["background-color"],
            }}
            type="button"
            onClick={() =>
              submitTrigger({
                type: "post",
                oauth2: field.info.OAuth2_Consumer__,
              })
            }
          >
            &nbsp;
          </button>
        );
      });
  };

  /* button */

  const buildBtns = () => {
    const initial = flow.data.initial;

    if (initial) {
      return (
        <div className={`${common["btn-group"]} ${login["login-btn-group"]}`}>
          <button
            type="submit"
            className={`${common["btn"]} ${common["btn--center"]}`}
          >
            {t("head_login")}
          </button>
        </div>
      );
    } else {
      return (
        <div className={`${common["btn-group"]} ${login["login-btn-group"]}`}>
          <button
            type="button"
            onClick={() => {
              submitTrigger({ type: "reset" });
            }}
            className={`${common["btn"]} ${common["btn--glay"]}`}
          >
            {t("login_back")}
          </button>
          <button type="submit" className={`${common["btn"]}`}>
            {t("head_login")}
          </button>
        </div>
      );
    }
  };

  if (
    user === null ||
    flow === null ||
    session === null ||
    form === null ||
    transitionStatus === true
  ) {
    return (
      <div
        className={login["login"]}
        style={{ backgroundImage: `url(${bgLogin})` }}
      >
        <LoginLoading />
      </div>
    );
  }

  if (user && user.data) {
    return history.location.search ? (
      <Redirect to={`${history.location.search.split("?return_to=")[1]}`} />
    ) : (
      <Redirect to="/" />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t("login_title")} | {t("head_title_logo")}
        </title>
      </Helmet>
      <div
        className={login["login"]}
        style={{ backgroundImage: `url(${bgLogin})` }}
      >
        <LoginLoading type="fade" />
        <div className={login["login-inner"]}>
          <h1 className={login["login-title"]}>{t("login_title")}</h1>
          <div className={login["login-form"]}>
            <form onSubmit={(e) => submitTrigger({ type: "submit" }, e)}>
              {buildList()}
              <div
                className={`${common["form-oauth"]} ${login["login-form-oauth"]}`}
              >
                {buildOauthBtns()}
              </div>
              {buildBtns()}
            </form>
          </div>
        </div>

        <Link
          to="/"
          onClick={() => {
            setDirection("toLeft");
          }}
          className={`${common["scroll-btn"]} ${common["scroll-btn--left"]} ${login["login-scroll-btn"]}`}
        ></Link>
      </div>
      <Toast duration={5000} />
    </>
  );
};

export default Login;
