import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Text = () => {
  const { t } = useTranslation();
  const recoverUrl = `https://vp.net/reset-password?login=${
    Get("_recover_user").User_Id
  }&password_key=${encodeURIComponent(Get("_user_key"))}`;

  return (
    <textarea
      id="mail-text"
      defaultValue={
        t("user_password_mail_notice") +
        "\n\r" +
        recoverUrl +
        "\n\r" +
        t("user_password_mail_regards") +
        "\n\r" +
        t("user_password_email_footer") +
        "\n\r" +
        t("user_password_mail_date_notice", {
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }) +
        "\n\r"
      }
    />
  );
};

export default Text;
