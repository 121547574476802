import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// component
import Helmet from "components/Helmet/Helmet";
import Footer from "components/layout/footer";

// style
import common from "assets/scss/common.module.scss";

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>vp net</title>
      </Helmet>
      <main className={common["common-layout"]}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
