import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Link as HashLink } from "react-scroll";

// style
import common from "assets/scss/common.module.scss";

// component
import useWindowSize from "components/utils/windowSize/windowSize";

export default function IndexHeader({ setDirection, setMenuToggle }) {
  const { t } = useTranslation();
  const size = useWindowSize();
  const location = useLocation();

  const typeOffset = {
    pc: {
      solution: -100,
      benefit: -300,
      region: -280,
      faq: -280,
    },
    sp: {
      solution: -103,
      benefit: -103,
      region: -103,
      faq: -103,
    },
  };

  const setOffset = (OffsetName) => {
    if (size.width > 960) {
      return typeOffset.pc[OffsetName];
    } else {
      return typeOffset.sp[OffsetName];
    }
  };

  return (
    <>
      <ul className={common["header-menu"]}>
        <li className={common["header-menu-item"]}>
          {location.pathname === "/" ? (
            <HashLink
              to="solution"
              offset={setOffset("solution")}
              smooth={true}
              duration={300}
              onClick={() => {
                setMenuToggle(false);
              }}
            >
              {t("head_solutions")}
            </HashLink>
          ) : (
            <Link to="/#solution">{t("head_solutions")}</Link>
          )}
        </li>
        <li className={common["header-menu-item"]}>
          {location.pathname === "/" ? (
            <HashLink
              to="benefit"
              offset={setOffset("benefit")}
              smooth={true}
              duration={300}
              onClick={() => {
                setMenuToggle(false);
              }}
            >
              {t("head_benefits")}
            </HashLink>
          ) : (
            <Link to="/#benefit">{t("head_benefits")}</Link>
          )}
        </li>
        <li className={common["header-menu-item"]}>
          {location.pathname === "/" ? (
            <HashLink
              to="region"
              offset={setOffset("region")}
              smooth={true}
              duration={300}
              onClick={() => {
                setMenuToggle(false);
              }}
            >
              {t("head_region")}
            </HashLink>
          ) : (
            <Link to="/#region">{t("head_region")}</Link>
          )}
        </li>
        <li className={common["header-menu-item"]}>
          {location.pathname === "/" ? (
            <HashLink
              to="faq"
              offset={setOffset("faq")}
              smooth={true}
              duration={300}
              onClick={() => {
                setMenuToggle(false);
              }}
            >
              {t("head_faq")}
            </HashLink>
          ) : (
            <Link to="/#faq">{t("head_faq")}</Link>
          )}
        </li>
        <li className={common["header-menu-item"]}>
          <Link
            to="/terms"
            onClick={() => {
              setDirection("toRight");
            }}
          >
            {t("head_terms")}
          </Link>
        </li>
        <li className={common["header-menu-item"]}>
          <Link
            to="/blog"
            onClick={() => {
              setDirection("toRight");
            }}
          >
            {t("head_blog")}
          </Link>
        </li>
        <li className={common["header-menu-item"]}>
          <Link
            to="/contact"
            onClick={() => {
              setDirection("toRight");
            }}
          >
            {t("head_contact")}
          </Link>
        </li>
      </ul>
    </>
  );
}
