import React from "react";
import { useTranslation } from "react-i18next";

//img
import iconPremium from "assets/images/icon-fearures-premium.svg";
import iconDdos from "assets/images/icon-features-ddos.svg";
import iconDelivered from "assets/images/icon-features-delivered.svg";
import iconTechnical from "assets/images/icon-features-technical.svg";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/product.module.scss";

const Features = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`${common["wrapper"]} ${common["wrapper--green"]} ${style["product-features-wrapper"]}`}
    >
      <div
        className={`${common["wrapper-inner"]} ${style["product-features"]}`}
      >
        <h2
          className={`${common["box-title"]} ${common["box-title-black"]} ${style["product-features-title"]}`}
          dangerouslySetInnerHTML={{ __html: t("product_title_features") }}
        ></h2>

        <dl className={style["product-features-list"]}>
          <div className={style["product-features-item"]}>
            <dt
              className={`${style["product-features-item-img"]} ${style["product-features-item-img--delivered"]}`}
            >
              <img
                src={iconDelivered}
                alt={t("product_features_title_delivered")}
              />
            </dt>
            <dd
              className={style["product-features-item-text"]}
              dangerouslySetInnerHTML={{
                __html: t("product_features_title_delivered"),
              }}
            ></dd>
          </div>
          <div className={style["product-features-item"]}>
            <dt
              className={`${style["product-features-item-img"]} ${style["product-features-item-img--ddos"]}`}
            >
              <img src={iconDdos} alt={t("product_features_title_ddos")} />
            </dt>
            <dd
              className={style["product-features-item-text"]}
              dangerouslySetInnerHTML={{
                __html: t("product_features_title_ddos"),
              }}
            ></dd>
          </div>
          <div className={style["product-features-item"]}>
            <dt
              className={`${style["product-features-item-img"]} ${style["product-features-item-img--technical"]}`}
            >
              <img
                src={iconTechnical}
                alt={t("product_features_title_technical")}
              />
            </dt>
            <dd
              className={style["product-features-item-text"]}
              dangerouslySetInnerHTML={{
                __html: t("product_features_title_technical"),
              }}
            ></dd>
          </div>
          <div className={style["product-features-item"]}>
            <dt
              className={`${style["product-features-item-img"]} ${style["product-features-item-img--premium"]}`}
            >
              <img
                src={iconPremium}
                alt={t("product_features_title_premium")}
              />
            </dt>
            <dd
              className={style["product-features-item-text"]}
              dangerouslySetInnerHTML={{
                __html: t("product_features_title_premium"),
              }}
            ></dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default Features;
