import React from "react";
import Helmet from "components/Helmet/Helmet";
import { Link } from "react-router-dom";

// component
import Header from "components/layout/header";

const NotFound = ({ setDirection }) => {
  return (
    <>
      <Helmet>
        <title>Not Found : vp net</title>
      </Helmet>
      <Header setDirection={setDirection}></Header>
      <h1>test</h1>
      <Link
        onClick={() => {
          setDirection("toLeft");
        }}
        to="/"
      >
        Link to back
      </Link>
    </>
  );
};

export default NotFound;
