import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

// component
import IndexHeader from "./section/indexHeader";
import LoginMenu from "components/layout/section/loginMenu";
import TopicPath from "components/layout/section/topic-path";

// style
import common from "assets/scss/common.module.scss";

// img
import logo from "assets/images/logo.svg";

export default function Header({ setDirection, type, path }) {
  const { t } = useTranslation();
  const [menuToggle, setMenuToggle] = useState(false);
  const userRefresh = useRestRefresh("User:get");
  const [user] = useRest("User:get");
  const history = useHistory();

  const buildMenu = () => {
    switch (type) {
      case "index":
        return (
          <IndexHeader
            user={user}
            setMenuToggle={setMenuToggle}
            setDirection={setDirection}
          />
        );
      case "lower":
        return <TopicPath setDirection={setDirection} path={path} />;
      default:
        return (
          <IndexHeader
            user={user}
            setMenuToggle={setMenuToggle}
            setDirection={setDirection}
          />
        );
    }
  };

  const toLogin = () => {
    setDirection("toTop");
    setMenuToggle(false);
    history.push("/login");
  };

  const logOut = () => {
    rest("User/@:logout", "POST").then(() => {
      userRefresh();
      setMenuToggle(false);
    });
  };

  const buildLogin = () => {
    if (user === null) return false;

    if (user.data === null) {
      return (
        <button
          className={`${common["btn"]} ${common["header-login"]}`}
          onClick={() => {
            toLogin();
          }}
        >
          {t("head_login")}
        </button>
      );
    } else {
      return (
        <button
          className={`${common["btn"]} ${common["header-login"]}`}
          onClick={() => {
            logOut();
          }}
        >
          {t("head_logout")}
        </button>
      );
    }
  };

  return (
    <>
      <header
        className={classNames(`${common["header"]} ${common["wrapper"]}`, {
          [common["header-lower"]]: type === "lower",
        })}
      >
        <div className={`${common["wrapper-inner"]} ${common["header-inner"]}`}>
          <h1 className={common["header-logo"]}>
            <Link
              to="/"
              onClick={() => {
                setDirection("toLeft");
              }}
            >
              <img src={logo} alt={t("head_title_logo")} />
            </Link>
          </h1>
          <div className={common["header-menu--sp"]}>
            <LoginMenu
              setDirection={setDirection}
              setMenuToggle={setMenuToggle}
              user={user}
              type="sp"
            />
            <button
              className={common["header-menu--sp--button"]}
              onClick={() => {
                setMenuToggle(!menuToggle);
              }}
            >
              <div
                className={classNames(common["header-menu--sp--button-inner"], {
                  [common["header-menu--sp--button-inner--active"]]: menuToggle,
                })}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
          <div className={common["header-menu-wrapper"]}>
            <div className={common["header-menu-inner"]}>
              {buildMenu()}
              <LoginMenu
                setDirection={setDirection}
                setMenuToggle={setMenuToggle}
                user={user}
              />
            </div>
          </div>
        </div>
      </header>
      {type === "lower" && (
        <div className={common["topic-path-wrapper"]}>
          <TopicPath setDirection={setDirection} path={path} />
        </div>
      )}
      <div
        className={classNames(
          common["header-menu-wrapper"],
          common["header-menu-wrapper--sp"],
          {
            [common["header-menu-wrapper--active"]]: menuToggle,
            [common["header-menu-wrapper--active-lower"]]:
              menuToggle && type === "lower",
            [common["header-menu-wrapper-lower"]]: type === "lower",
          }
        )}
      >
        <div className={common["header-menu-inner"]}>
          <IndexHeader
            user={user}
            setMenuToggle={setMenuToggle}
            setDirection={setDirection}
          />
          {buildLogin()}
        </div>
      </div>
    </>
  );
}
