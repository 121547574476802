import React from "react";
import { useTranslation } from "react-i18next";

import Text from "./EmailText";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    backgroundColor: "#d9d9d9",
    borderCollapse: "collapse",
    tableLayout: "fixed",
    width: "100%",
  },

  pageInner: {
    width: "100%",
  },

  tableWrapper: {
    borderCollapse: "collapse",
    tableLayout: "fixed",
    width: "100%",
    maxWidth: "720px",
    backgroundColor: "#fff",
    borderRadius: "24px",
    boxShadow: "0 0 12px rgba(0, 0, 0, .25)",
  },
}));

const EmailLayout = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div id="mail-html">
      <table
        className={classes.pageWrapper}
        border="0"
        cellSpacing="0"
        cellPadding="24"
        align="center"
      >
        <tbody>
          <tr>
            <td className={classes.pageInner} align="center">
              <table
                className={classes.tableWrapper}
                border="0"
                cellSpacing="0"
                cellPadding="24"
              >
                <tbody>
                  <tr>
                    <td>
                      <a
                        href="https://vp.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title={t("go_to_website")}
                      >
                        <img
                          src="https://vp.net/img/vpnet-email-banner-new.png"
                          width="100%"
                          alt=""
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{children}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <Text align="center" size="small">
                {t("automatic_message")}
              </Text>
              <Text align="center" size="normal">
                {new Date().getFullYear()} © &nbsp;
                <a
                  href="https://vp.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={t("go_to_website")}
                  color="#72BA3E"
                  style={{ color: "#72BA3E", textDecoration: "underline" }}
                >
                  vp.net
                </a>
              </Text>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmailLayout;
