import React from "react";
import { useTranslation } from "react-i18next";
import { Get } from "@karpeleslab/klbfw";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/forgotten.module.scss";

// component
import Helmet from "components/Helmet/Helmet";
import Form from "./form";

const PasswordForgotten = () => {
  const { t } = useTranslation();
  const login = Get("login");
  const password_key = Get("password_key");

  return (
    <>
      <Helmet>
        <title>{t("password_reset_title")}</title>
      </Helmet>
      <h1 className={`${common["common-title"]} ${style["forgotten-title"]}`}>
        {t("password_reset_title")}
      </h1>
      <Form resetKey={password_key} login={login} />
    </>
  );
};

export default PasswordForgotten;
