import React from "react";
import { useTranslation } from "react-i18next";
import { Link as HashLink } from "react-scroll";

// component
import useWindowSize from "components/utils/windowSize/windowSize";

// style
import common from "assets/scss/common.module.scss";
import index from "assets/scss/index.module.scss";

//img
import cloud from "assets/images/img-cloud.svg";
import cloudBlur from "assets/images/img-cloud-blur.svg";
import cloudBlurSmall from "assets/images/img-cloud-blur-2.svg";

const Hero = () => {
  const size = useWindowSize();
  const { t } = useTranslation();

  const setOffset = () => {
    if (size.width > 960) {
      return -100;
    } else {
      return -103;
    }
  };

  return (
    <>
      <div
        className={`${common["wrapper"]} ${common["wrapper--glay"]} ${index["hero-wrapper"]}`}
      >
        <div className={`${common["wrapper-inner"]} ${index["hero"]}`}>
          <p
            className={index["hero-title"]}
            dangerouslySetInnerHTML={{ __html: t("top_hero_catch") }}
          />

          <div className={index["hero-info"]}>
            <p className={index["hero-info-text"]}>{t("top_hero_info")}</p>
            <HashLink
              className={index["hero-info-btn"]}
              to="solution"
              offset={setOffset()}
              smooth={true}
              duration={300}
            >
              {t("common_btn_more")}
            </HashLink>
          </div>
          <HashLink
            to="solution"
            offset={setOffset()}
            smooth={true}
            duration={300}
            className={`${common["scroll-btn"]} ${common["scroll-btn--bottom"]} ${index["hero-scroll-btn"]}`}
          ></HashLink>
        </div>
        <img
          className={`${index["hero-cloud"]} ${index["hero-cloud-one"]}`}
          src={cloud}
          alt={t("top_hero_catch_alt")}
        />
        <img
          className={`${index["hero-cloud"]} ${index["hero-cloud-two"]}`}
          src={cloudBlur}
          alt={t("top_hero_catch_alt")}
        />
        <img
          className={`${index["hero-cloud"]} ${index["hero-cloud-three"]}`}
          src={cloud}
          alt={t("top_hero_catch_alt")}
        />
        <img
          className={`${index["hero-cloud"]} ${index["hero-cloud-four"]}`}
          src={cloudBlurSmall}
          alt={t("top_hero_catch_alt")}
        />
      </div>
    </>
  );
};

export default Hero;
