import React, { useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Provider } from "store/store";

// layout
import Layout from "components/layout/layout";
import LayoutLower from "components/layout/layout-lower";
import LayoutTerms from "components/layout/layout-terms";
import LayoutContact from "components/layout/layout-contact";

// page
import Index from "views/index/index";
import Login from "views/login/login";
import Terms from "views/terms/terms";
import Product from "views/product/product";
import Order from "views/order/order";
import NotFound from "components/NotFound/NotFound";
import Contact from "views/contact/Contact";
import Blog from "views/blog/blog";
import BlogArticle from "views/blog/blogArticle";
import PasswordForgotten from "views/passwordForgotten/passwordForgotten";
import PasswordReset from "views/passwordReset/passwordReset";

// mail
import BillingFailed from "mails/Order/Billing/Failed/Mail";
import BillingInvalidate from "mails/Order/Billing/Invalidate/Mail";
import NewOrder from "mails/Order/Created/Mail";
import BillPdf from "mails/Order/Billing/Pdf/Pdf";
import ForgottenPasswordMail from "mails/Password/Forgotten/Mail";
import RecoverPasswordMail from "mails/Password/Recover/Mail";

const Mail = () => {
  return (
    <Switch>
      <Route exact path="/mail/order/billing_failed.mail">
        <BillingFailed />
      </Route>
      <Route exact path="/mail/order/billing_method_invalidate.mail">
        <BillingInvalidate />
      </Route>
      <Route exact path="/mail/order/order.mail">
        <NewOrder />
      </Route>
      <Route exact path="/mail/order/bill.mail">
        <BillPdf />
      </Route>
      <Route exact path="/mail/mail_forgotten_pwd.mail">
        <ForgottenPasswordMail />
      </Route>
      <Route exact path="/mail/mail_pwd_recovered.mail">
        <RecoverPasswordMail />
      </Route>
    </Switch>
  );
};

const buildLayout = (component, layoutType = "normal", setDirection) => {
  return (
    <div className="route-section fade">
      {layoutType === "normal" && <Layout>{component}</Layout>}
      {layoutType === "lower" && <LayoutLower>{component}</LayoutLower>}
      {layoutType === "terms" && (
        <LayoutTerms setDirection={setDirection}>{component}</LayoutTerms>
      )}
      {layoutType === "contact" && (
        <LayoutContact setDirection={setDirection}>{component}</LayoutContact>
      )}
    </div>
  );
};

const Pages = () => {
  const [direction, setDirectionStatus] = useState("toLeft");
  const [transitionStatus, setTransitionStatus] = useState(false);

  let location = useLocation();

  const transitionHandler = (boolean) => {
    setTransitionStatus(boolean);
  };

  const setDirection = (direction, status = true) => {
    setDirectionStatus(direction);
    if (direction !== "toLeft") {
      setTransitionStatus(status);
    }
  };

  return (
    <Provider>
      <TransitionGroup className={`wrap ${direction}`}>
        <CSSTransition
          key={location.key}
          classNames="pageSlider"
          mountOnEnter={false}
          unmountOnExit={true}
          addEndListener={(node, done) => {
            node.addEventListener(
              "transitionend",
              (e) => {
                if (e.target !== node) return false;
                done(e);
                transitionHandler(false);
              },
              false
            );
          }}
        >
          <Switch location={location}>
            <Route exact path="/">
              {buildLayout(<Index setDirection={setDirection} />)}
            </Route>
            <Route exact path="/login">
              <Login
                setDirection={setDirection}
                transitionStatus={transitionStatus}
              />
            </Route>
            <Route exact path="/terms">
              {buildLayout(<Terms />, "terms", setDirection)}
            </Route>
            <Route exact path="/product/:productId">
              {buildLayout(<Product setDirection={setDirection} />, "lower")}
            </Route>
            <Route exact path="/join">
              {buildLayout(<Order setDirection={setDirection} />, "lower")}
            </Route>
            <Route exact path="/contact">
              {buildLayout(<Contact />, "contact", setDirection)}
            </Route>
            <Route exact path="/forget-password">
              {buildLayout(<PasswordForgotten />, "contact", setDirection)}
            </Route>
            <Route exact path="/reset-password">
              {buildLayout(<PasswordReset />, "contact", setDirection)}
            </Route>
            <Route exact path="/blog">
              {buildLayout(<Blog setDirection={setDirection} />, "lower")}
            </Route>
            <Route exact path="/blog/:slug">
              {buildLayout(
                <BlogArticle setDirection={setDirection} />,
                "lower"
              )}
            </Route>
            <Route>
              {buildLayout(<NotFound setDirection={setDirection} />)}
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Provider>
  );
};

const Core = () => {
  return (
    <Switch>
      <Route path="/mail">
        <Mail />
      </Route>
      <Route>
        <Pages />
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return <Core />;
};

export default Routing;
