import React, { useEffect, useState, useContext } from "react";
import { Store } from "store/store";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import _ from "lodash";

// component
import Helmet from "components/Helmet/Helmet";
import Header from "components/layout/header";
import SelectPlan from "./section/selectPlan";
import PaymentMethod from "./section/paymentMethod";

// constant
import {
  CATALOG_PRODUCT_SMALL,
  CATALOG_PRODUCT_MEDIUM,
  CATALOG_PRODUCT_LARGE,
  CATALOG_PRODUCT_CUSTOM,
} from "store/reducer";

const Oeder = ({ setDirection }) => {
  const { state, dispatch } = useContext(Store);
  const { t } = useTranslation();
  const [plans] = useRest("Catalog/Product:search", "Post");
  const [small, setSmall] = useState(null);
  const [medium, setMedium] = useState(null);
  const [large, setLarge] = useState(null);
  const [custom, setCustom] = useState(null);

  useEffect(() => {
    if (!plans) return false;
    setSmall(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_SMALL])
    );
    setMedium(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_MEDIUM])
    );
    setLarge(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_LARGE])
    );
    setCustom(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_CUSTOM])
    );
  }, [plans]);

  const buildProductName = () => {
    if (state.selected === null) return false;

    switch (state.selected.Catalog_Product__) {
      case CATALOG_PRODUCT_SMALL:
        return "Small";
      case CATALOG_PRODUCT_MEDIUM:
        return "Medium";
      case CATALOG_PRODUCT_LARGE:
        return "Large";
      case CATALOG_PRODUCT_CUSTOM:
        return "Custom";
      default:
        return "Custom";
    }
  };

  const buildProductPath = () => {
    if (state.selected === null) return false;

    switch (state.selected.Catalog_Product__) {
      case CATALOG_PRODUCT_SMALL:
        return "small";
      case CATALOG_PRODUCT_MEDIUM:
        return "medium";
      case CATALOG_PRODUCT_LARGE:
        return "large";
      case CATALOG_PRODUCT_CUSTOM:
        return "custom";
      default:
        return "custom";
    }
  };

  const path = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Solutions",
      path: "/#solution",
    },
    {
      name: buildProductName(),
      path: `/product/${buildProductPath()}`,
    },
    {
      name: "Purchase",
    },
  ];

  useEffect(() => {
    if (state.selected === null) {
      dispatch({
        type: "SELECTED_DONE",
        selected: small,
      });
    }
  }, [dispatch, state.selected, small, medium, large, custom]);

  if (
    state.selected === null ||
    small === null ||
    medium === null ||
    large === null ||
    custom === null
  )
    return false;

  return (
    <>
      <Helmet>
        <title>
          {t("purchase_title")} | {t("head_title_logo")}
        </title>
      </Helmet>
      <Header setDirection={setDirection} type="lower" path={path} />
      <SelectPlan small={small} medium={medium} large={large} custom={custom} />
      <PaymentMethod />
    </>
  );
};

export default Oeder;
