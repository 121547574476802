import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    backgroundColor: "#002561",
    color: "#fff",
    borderRadius: "24px",
    fontWeight: "bold",
  },
}));

export default ({ children, ...etc }) => {
  const classes = useStyles();

  return (
    <a {...etc}>
      <table
        border="0"
        cellSpacing="0"
        cellPadding="12"
        className={classes.buttonStyle}
        background="#002561"
      >
        <tbody>
          <td>&nbsp;</td>
          <td color="#ffffff">{children}</td>
          <td>&nbsp;</td>
        </tbody>
      </table>
    </a>
  );
};
