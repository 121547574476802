import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import Countries from "components/countries/countries";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/order.module.scss";

const CreateBilling = ({ setBillingEnabled, setBillingValues }) => {
  const { t } = useTranslation();

  // billing creation
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState(
    getLocale().split("-")[1].toUpperCase()
  );

  useEffect(() => {
    if (
      !firstname.trim() ||
      !lastname.trim() ||
      !zip.trim() ||
      !country.trim()
    ) {
      setBillingEnabled(false);
      return;
    }

    setBillingEnabled(true);
    setBillingValues({
      First_Name: firstname,
      Last_Name: lastname,
      Zip: zip,
      Country__: country,
    });
  }, [firstname, lastname, zip, country, setBillingEnabled, setBillingValues]);

  return (
    <div className={style["order-create-pay"]}>
      <h3 className={style["order-create-pay-title"]}>
        {t("purchase_pay_payment_title")}
      </h3>
      <div
        className={`${common["form-input"]} ${style["order-create-pay-input"]}`}
      >
        <input
          type="text"
          placeholder={t("purchase_pay_payment_fname")}
          onChange={(e) => {
            setFirstname(e.target.value);
          }}
          value={firstname}
        />
      </div>
      <div
        className={`${common["form-input"]} ${style["order-create-pay-input"]}`}
      >
        <input
          type="text"
          placeholder={t("purchase_pay_payment_lname")}
          onChange={(e) => {
            setLastname(e.target.value);
          }}
          value={lastname}
        />
      </div>
      <div
        className={`${common["form-input"]} ${style["order-create-pay-input"]}`}
      >
        <input
          type="text"
          placeholder={t("purchase_pay_payment_zip")}
          onChange={(e) => {
            setZip(e.target.value);
          }}
          value={zip}
        />
      </div>
      <Countries value={country} onChange={(e) => setCountry(e.target.value)} />
    </div>
  );
};

export default CreateBilling;
