import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "store/store";
import classNames from "classnames";

//img
import iconProcessor from "assets/images/icon-processor.svg";
import iconMemory from "assets/images/icon-memory.svg";
import iconStorage from "assets/images/icon-storage.svg";

// icon
import iconUp from "assets/images/icon-triangle-up.svg";
import iconDown from "assets/images/icon-triangle-down.svg";

// style
import style from "assets/scss/spec.module.scss";
import product from "assets/scss/product.module.scss";

// constant
import { CATALOG_PRODUCT_CUSTOM } from "store/reducer";

const Spec = ({ selectedPlan, type = "" }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [selectedCpu, setSelectedCpu] = useState(8);
  const [selectedMemory, setSelectedMemory] = useState(32);
  const [selectedStorage, setSelectedStorage] = useState(750);

  const changeUnit = (type) => {
    switch (type) {
      case "up":
        dispatch({
          type: "UNIT_CHANGE",
          nbUnit: state.nbUnit + 1,
        });
        break;
      case "down":
        if (state.nbUnit <= 8) return false;
        dispatch({
          type: "UNIT_CHANGE",
          nbUnit: state.nbUnit - 1,
        });
        break;
      default:
        break;
    }
  };

  const cpuUnit = () => {
    if (selectedPlan.Catalog_Product__ !== CATALOG_PRODUCT_CUSTOM) return false;

    return (
      <div className={style["spec-item-unit-control"]}>
        <button
          onClick={() => {
            changeUnit("up");
          }}
          className={style["spec-item-unit-control-btn"]}
        >
          <img src={iconUp} alt="Up" />
        </button>
        <button
          onClick={() => {
            changeUnit("down");
          }}
          className={style["spec-item-unit-control-btn"]}
        >
          <img src={iconDown} alt="Down" />
        </button>
      </div>
    );
  };

  const memoryUnit = () => {
    if (selectedPlan.Catalog_Product__ !== CATALOG_PRODUCT_CUSTOM) return false;

    return (
      <div className={style["spec-item-unit-control"]}>
        <button
          onClick={() => {
            changeUnit("up");
          }}
          className={style["spec-item-unit-control-btn"]}
        >
          <img src={iconUp} alt="Up" />
        </button>
        <button
          onClick={() => {
            changeUnit("down");
          }}
          className={style["spec-item-unit-control-btn"]}
        >
          <img src={iconDown} alt="Down" />
        </button>
      </div>
    );
  };

  const storageUnit = () => {
    if (selectedPlan.Catalog_Product__ !== CATALOG_PRODUCT_CUSTOM) return false;

    return (
      <div className={style["spec-item-unit-control"]}>
        <button
          onClick={() => {
            changeUnit("up");
          }}
          className={style["spec-item-unit-control-btn"]}
        >
          <img src={iconUp} alt="Up" />
        </button>
        <button
          onClick={() => {
            changeUnit("down");
          }}
          className={style["spec-item-unit-control-btn"]}
        >
          <img src={iconDown} alt="Down" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    setSelectedCpu(
      Math.ceil(
        (selectedPlan["Shell.Size"]
          ? selectedPlan["Shell.Size"]
          : state.nbUnit) / 4
      )
    );
    setSelectedMemory(
      Math.ceil(
        (selectedPlan["Shell.Size"]
          ? selectedPlan["Shell.Size"]
          : state.nbUnit) * 0.512
      )
    );
    setSelectedStorage(
      (selectedPlan["Shell.Size"] ? selectedPlan["Shell.Size"] : state.nbUnit) *
        10
    );
  }, [selectedPlan, setSelectedCpu, setSelectedMemory, setSelectedStorage]);

  useEffect(() => {
    if (selectedPlan.Catalog_Product__ !== CATALOG_PRODUCT_CUSTOM) return false;
    setSelectedCpu(Math.ceil(state.nbUnit / 4));
    setSelectedMemory(Math.ceil(0.512 * state.nbUnit));
    setSelectedStorage(10 * state.nbUnit);
  }, [state.nbUnit]);

  return (
    <div
      className={classNames(style["spec"], {
        [style["spec-big"]]: type === "big",
        [product["product-buy-spec"]]: type === "product",
      })}
    >
      <div className={`${style["spec-item"]} ${style["spec-item--processor"]}`}>
        <div className={style["spec-item-title"]}>
          <img
            className={style["spec-item-title-img--processor"]}
            src={iconProcessor}
            alt={t("product_buy_processor")}
          />
          <h2 className={style["spec-item-title-text"]}>
            {t("product_buy_processor")}
          </h2>
        </div>
        <div className={style["spec-item-unit"]}>
          <p className={style["spec-item-unit-number"]}>
            {selectedCpu}
            <span>CPU</span>
          </p>
          {cpuUnit()}
        </div>
      </div>

      <div className={`${style["spec-item"]} ${style["spec-item--memory"]}`}>
        <div className={style["spec-item-title"]}>
          <img
            className={style["spec-item-title-img--memory"]}
            src={iconMemory}
            alt={t("product_buy_memory")}
          />
          <h2 className={style["spec-item-title-text"]}>
            {t("product_buy_memory")}
          </h2>
        </div>
        <div className={style["spec-item-unit"]}>
          <p className={style["spec-item-unit-number"]}>
            {selectedMemory}
            <span>GB</span>
          </p>
          {memoryUnit()}
        </div>
      </div>

      <div className={`${style["spec-item"]} ${style["spec-item--storage"]}`}>
        <div className={style["spec-item-title"]}>
          <img
            className={style["spec-item-title-img--storage"]}
            src={iconStorage}
            alt={t("product_buy_storage")}
          />
          <h2 className={style["spec-item-title-text"]}>
            {t("product_buy_storage")}
          </h2>
        </div>
        <div className={style["spec-item-unit"]}>
          <p className={style["spec-item-unit-number"]}>
            {selectedStorage}
            <span>GB</span>
          </p>
          {storageUnit()}
        </div>
      </div>
    </div>
  );
};

export default Spec;
