import { Checkbox, Grid, Input, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
// component
import Toast, { error } from "components/toast/toast";

// style
import common from "../../assets/scss/common.module.scss";
import style from "assets/scss/contact.module.scss";

export const ContactForm = () => {
  const { t } = useTranslation();

  let [name, setName] = useState("");
  let [company, setCompany] = useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [message, setMessage] = useState("");
  let [checkbox1, setCheckbox1] = useState(false);
  let [checkbox2, setCheckbox2] = useState(false);

  const updateState = (event, cb) => cb(event.target.value);
  const sendContactForm = () => {
    // update when more information
    const msg = `Company: ${company}
         Phone: ${phone}
         Checkbox1: ${checkbox1 ? "Yes" : "No"}
         Checkbox2: ${checkbox2 ? "Yes" : "No"}
         Message:
         ${message}
        `;

    const payload = {
      Email: email,
      Name: name,
      To: "@support",
      Subject: "Contact us",
      Message: msg,
      Tags: [],
    };

    rest("Support/Ticket", "POST", payload)
      .then(() => {
        this.props.history.push("/");
      })
      .catch((response) => {
        error(response.message, false);
      });
  };

  return (
    <>
      <div className={style["contact"]}>
        <Grid container spacing={8} direction="row">
          <Grid item xs={12} md={6}>
            <Grid container spacing={6} direction="row">
              <Grid item xs={12} md={6}>
                <p
                  className={`${common["common-label"]} ${style["contact-label"]}`}
                >
                  {t("contact_name")}
                </p>
                <Input
                  className={style["contact-input"]}
                  required
                  onChange={(event) => updateState(event, setName)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <p
                  className={`${common["common-label"]} ${style["contact-label"]}`}
                >
                  {t("contact_company")}
                </p>
                <Input
                  className={style["contact-input"]}
                  required
                  onChange={(event) => updateState(event, setCompany)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <p
                  className={`${common["common-label"]} ${style["contact-label"]}`}
                >
                  {t("contact_email")}
                </p>
                <Input
                  type="email"
                  className={style["contact-input"]}
                  required
                  onChange={(event) => updateState(event, setEmail)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <p
                  className={`${common["common-label"]} ${style["contact-label"]}`}
                >
                  {t("contact_phone")}
                </p>
                <Input
                  className={style["contact-input"]}
                  required
                  onChange={(event) => updateState(event, setPhone)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={6} direction="column">
              <Grid item xs={12}>
                <p
                  className={`${common["common-label"]} ${style["contact-label"]}`}
                >
                  {t("contact_message")}
                </p>
                <TextField
                  className={[
                    style["contact-input"],
                    style["contact-message-margin"],
                  ].join(" ")}
                  multiline
                  rows={5}
                  variant="filled"
                  required
                  onChange={(event) => updateState(event, setMessage)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={6} direction="row">
                  <Grid item xs={6}>
                    <Checkbox
                      onChange={(event) => updateState(event, setCheckbox1)}
                    />
                    <span className={style["contact-text"]}>
                      {" "}
                      {t("contact_checkbox_1")}{" "}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <Checkbox
                      onChange={(event) => updateState(event, setCheckbox2)}
                    />
                    <span className={style["contact-text"]}>
                      {" "}
                      {t("contact_checkbox_2")}{" "}
                    </span>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={style["contact-button-container"]}>
                      <button
                        className={style["contact-button"]}
                        onClick={sendContactForm}
                      >
                        {t("contact_send")}
                      </button>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    <div className={style["contact-text"]}>
                      {" "}
                      {t("contact_send_text")}{" "}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Toast duration={5000} />
    </>
  );
};

export default ContactForm;
