import React, { useEffect } from "react";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

// component
import Helmet from "components/Helmet/Helmet";
import Header from "components/layout/header";
import Hero from "./section/hero";
import Solutions from "./section/solutions";
import Benefits from "./section/benefits";
import Regions from "./section/regions";
import Faq from "./section/faq";
import useWindowSize from "components/utils/windowSize/windowSize";

const Index = ({ setDirection }) => {
  const size = useWindowSize();
  const location = useLocation();

  const typeOffset = {
    pc: {
      solution: -100,
      benefit: -300,
      region: -280,
      faq: -280,
    },
    sp: {
      solution: -103,
      benefit: -103,
      region: -103,
      faq: -103,
    },
  };

  const setOffset = (OffsetName) => {
    if (size.width > 960) {
      return typeOffset.pc[OffsetName];
    } else {
      return typeOffset.sp[OffsetName];
    }
  };

  useEffect(() => {
    const hash = location.hash.split("#")[1];
    setTimeout(() => {
      if (hash === void 0) return false;
      scroller.scrollTo(hash, {
        duration: 300,
        smooth: true,
        offset: setOffset(hash),
      });
    }, 300);
  }, [location.hash]);

  return (
    <>
      <Helmet>
        <title>vp net</title>
      </Helmet>
      <Header setDirection={setDirection} type="index" />
      <Hero setDirection={setDirection} />
      <Solutions setDirection={setDirection} />
      <Benefits />
      <Regions />
      <Faq />
    </>
  );
};

export default Index;
