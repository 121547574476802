import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  box: {
    border: "1px solid #002561",
    borderRadius: "12px",
    boxShadow: "0 6px 6px rgba(0, 0, 0, .1)",
  },
}));

export default ({ children, ...etc }) => {
  const classes = useStyles();

  return (
    <table
      border="0"
      cellSpacing="0"
      cellPadding="12"
      className={classes.root}
      background="#002561"
      width="100%"
      align="center"
      {...etc}
    >
      <tbody>
        <td align="center">
          <table
            border="0"
            cellPadding="12"
            className={classes.box}
            align="center"
          >
            <tbody>
              <tr>
                <td>{children}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tbody>
    </table>
  );
};
