import React from "react";
import { useTranslation } from "react-i18next";

//img
import planSmall from "assets/images/img-plan-small.svg";
import planMedium from "assets/images/img-plan-medium.svg";
import planLarge from "assets/images/img-plan-large.svg";
import planCustom from "assets/images/img-plan-custom.svg";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/product.module.scss";

// constant
import {
  CATALOG_PRODUCT_SMALL,
  CATALOG_PRODUCT_MEDIUM,
  CATALOG_PRODUCT_LARGE,
  CATALOG_PRODUCT_CUSTOM,
} from "store/reducer";

const Hero = ({ plan }) => {
  const { t } = useTranslation();

  return (
    <div className={`${common["wrapper"]} ${style["product-hero-wrapper"]}`}>
      <div className={`${common["wrapper-inner"]} ${style["product-hero"]}`}>
        <figure className={style["product-hero-img"]}>
          {plan.Catalog_Product__ === CATALOG_PRODUCT_SMALL && (
            <img src={planSmall} alt={t("product_small")} />
          )}
          {plan.Catalog_Product__ === CATALOG_PRODUCT_MEDIUM && (
            <img src={planMedium} alt={t("product_medium")} />
          )}
          {plan.Catalog_Product__ === CATALOG_PRODUCT_LARGE && (
            <img src={planLarge} alt={t("product_large")} />
          )}
          {plan.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM && (
            <img src={planCustom} alt={t("product_custom")} />
          )}
        </figure>
        <dl className={style["product-hero-info"]}>
          <dt className={style["product-hero-info-title"]}>
            {plan.Catalog_Product__ === CATALOG_PRODUCT_SMALL &&
              t("product_small")}
            {plan.Catalog_Product__ === CATALOG_PRODUCT_MEDIUM &&
              t("product_medium")}
            {plan.Catalog_Product__ === CATALOG_PRODUCT_LARGE &&
              t("product_large")}
            {plan.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM &&
              t("product_custom")}
          </dt>
          {plan.Catalog_Product__ === CATALOG_PRODUCT_SMALL && (
            <dd
              dangerouslySetInnerHTML={{ __html: t("product_small_desc") }}
              className={style["product-hero-info-text"]}
            ></dd>
          )}

          {plan.Catalog_Product__ === CATALOG_PRODUCT_MEDIUM && (
            <dd
              dangerouslySetInnerHTML={{ __html: t("product_medium_desc") }}
              className={style["product-hero-info-text"]}
            ></dd>
          )}

          {plan.Catalog_Product__ === CATALOG_PRODUCT_LARGE && (
            <dd
              dangerouslySetInnerHTML={{ __html: t("product_large_desc") }}
              className={style["product-hero-info-text"]}
            ></dd>
          )}

          {plan.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM && (
            <dd
              dangerouslySetInnerHTML={{ __html: t("product_custom_desc") }}
              className={style["product-hero-info-text"]}
            ></dd>
          )}
        </dl>
      </div>
    </div>
  );
};

export default Hero;
