import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import DateUtil from "components/common/data/DateUtil";
import { Link } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";
import Toast, { error } from "components/toast/toast";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/blog.module.scss";

// component
import Helmet from "components/Helmet/Helmet";
import Header from "components/layout/header";

// img
import defaultImg from "assets/images/icon-blog-default.png";

const Blog = ({ setDirection }) => {
  const { t } = useTranslation();
  const [pageNum, setPageNum] = useState(1);
  const [blogData, setBlogData] = useState(null);
  const [btnEnabled, setBtnEnabled] = useState(true);
  const THUMB_IMG_VARIATION = "format=jpeg&strip&scale_crop=360x360";
  const THUMB_SP_IMG_VARIATION = "format=jpeg&strip&scale_crop=143x230";
  const [blogs] = useRest(
    "Content/Cms/ctcm-mpygge-2mcj-czdh-svvn-d33obke4:search",
    {
      results_per_page: 10,
      page_no: pageNum,
      image_variation: [THUMB_IMG_VARIATION, THUMB_SP_IMG_VARIATION],
      sort: "published:desc",
    },
    false
  );

  useEffect(() => {
    if (blogs === null || blogData !== null) return;
    setBlogData(blogs.data.data);
  }, [blogs, setBlogData, blogData]);

  useEffect(() => {
    if (blogData?.length === blogs?.paging.count) {
      setBtnEnabled(false);
    } else {
      setBtnEnabled(true);
    }
  }, [blogData, blogs, setBtnEnabled]);

  const addBlog = () => {
    setBtnEnabled(false);
    const params = {
      results_per_page: 10,
      page_no: pageNum + 1,
      image_variation: [THUMB_IMG_VARIATION, THUMB_SP_IMG_VARIATION],
      sort: "published:desc",
    };

    rest(
      "Content/Cms/ctcm-mpygge-2mcj-czdh-svvn-d33obke4:search",
      "GET",
      params
    )
      .then((data) => {
        setBlogData([...blogData, ...data.data.data]);
        setPageNum(pageNum + 1);
      })
      .catch((response) => {
        error(response.message, false);
        setBtnEnabled(true);
      });
  };

  const path = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Blog",
    },
  ];

  const trimContents = (contents, length) => {
    contents = contents.slice(0, length);
    let lastIdx = contents.lastIndexOf(" ");
    contents = contents.substring(0, lastIdx);

    return contents;
  };

  const buildList = () => {
    return blogData.map((item, index) => {
      return (
        <div className={style["blog-item"]} key={index}>
          <Link
            to={`/blog/${item.Slug}`}
            onClick={() => {
              setDirection("toRight");
            }}
          >
            <div className={style["blog-item-container"]}>
              <div className={style["blog-item-img-sp"]}>
                <img
                  className={style["blog-item-img-sp-img"]}
                  src={
                    !item.Drive_Item.Media_Image
                      ? defaultImg
                      : item.Drive_Item.Media_Image[THUMB_SP_IMG_VARIATION]
                  }
                />
              </div>
              <img
                className={style["blog-item-img"]}
                src={
                  !item.Drive_Item.Media_Image
                    ? defaultImg
                    : item.Drive_Item.Media_Image[THUMB_IMG_VARIATION]
                }
              />
              <div className={style["blog-item-inner"]}>
                <h2 className={style["blog-item-title"]}>{item.Title}</h2>
                <span className={style["blog-item-date"]}>
                  <DateUtil klbDateObj={item.Created} format="MM.DD.YY" />
                </span>
                {item.Short_Contents && (
                  <p className={style["blog-item-desc"]}>
                    {trimContents(item.Short_Contents, 100)}
                  </p>
                )}
                <p className={style["blog-item-more"]}>{t("blog_more")}</p>
              </div>
            </div>
          </Link>
        </div>
      );
    });
  };

  if (blogData === null) return false;

  return (
    <>
      <Helmet>
        <title>
          {t("blog_title")} | {t("head_title")}
        </title>
      </Helmet>
      <Header setDirection={setDirection} type="lower" path={path} />
      <div>
        <div
          className={`${common["wrapper"]} ${common["wrapper--white"]} ${style["blog-container"]}`}
        >
          <div className={`${common["wrapper-inner"]} ${style["blog"]}`}>
            <h1 className={`${common["common-title"]} ${style["blog-title"]}`}>
              {t("blog_title")}
            </h1>
            {blogData.length === 0 && (
              <div className={style["blog-no-article"]}>
                {t("blog_no_article")}
              </div>
            )}
            {blogData.length !== 0 && (
              <>
                <div className={style["blog-list"]}>{buildList()}</div>
                <div className={style["blog-btn-group"]}>
                  <button
                    className={`${common["btn"]} ${style["blog-btn"]}`}
                    onClick={() => {
                      addBlog();
                    }}
                    disabled={!btnEnabled}
                  >
                    {t("common_btn_more")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Toast duration={5000} />
    </>
  );
};

export default Blog;
