import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

// style
import style from "assets/scss/loading.module.scss";

//img
import loading from "assets/images/icon-loading.svg";

const Loading = ({ setLoad, closeLoad = false }) => {
  const loadingRef = useRef(null);
  const progressRef = useRef(null);
  const progressBarRef = useRef(null);
  const [progressStatus, setProgressStatus] = useState("before");

  const progressStatusChange = () => {
    switch (progressStatus) {
      case "before":
        setProgressStatus("start");
        break;
      case "start":
        setProgressStatus("end");
        break;
      case "end":
        setProgressStatus("finish");
        break;
      case "finish":
        setLoad(false);
        break;
      default:
        setProgressStatus("before");
        break;
    }
  };

  useEffect(() => {
    const loadingRefCurrent = loadingRef.current;

    loadingRefCurrent.addEventListener("animationend", (e) => {
      if (e.target !== loadingRefCurrent) return false;

      if (progressStatus === "before" || progressStatus === "finish") {
        progressStatusChange();
      }
    });

    // init
    return () => {
      if (loadingRefCurrent !== null) {
        loadingRefCurrent.removeEventListener("animationend", () => {});
      }
    };
  }, [loadingRef, progressStatus, progressStatusChange]);

  useEffect(() => {
    const progressRefCurrent = progressRef.current;
    progressRefCurrent.addEventListener("animationend", (e) => {
      if (e.target !== progressRefCurrent) return false;
      progressStatusChange();
    });

    // init
    return () => {
      if (progressRefCurrent !== null) {
        progressRefCurrent.removeEventListener("animationend", () => {});
      }
    };
  }, [progressRef, progressStatusChange]);

  useEffect(() => {
    const progressBarRefCurrent = progressBarRef.current;

    progressBarRefCurrent.addEventListener("animationend", (e) => {
      if (e.target !== progressBarRefCurrent) return false;

      progressStatusChange();
    });

    // init
    return () => {
      if (progressBarRefCurrent !== null) {
        progressBarRefCurrent.removeEventListener("animationend", () => {});
      }
    };
  }, [progressBarRef, progressStatusChange]);

  return (
    <>
      <div
        ref={loadingRef}
        className={classNames(style["loading"], {
          [style["loading-close"]]: progressStatus === "finish" && closeLoad,
        })}
      >
        <div className={style["loading-wrapper"]}>
          <img
            className={classNames(style["loading-logo"], {
              [style["loading-logo-end"]]: progressStatus === "end",
              [style["loading-logo-finish"]]: progressStatus === "finish",
            })}
            src={loading}
            alt=""
          />
          <div
            ref={progressRef}
            className={classNames(style["loading-progress"], {
              [style["loading-progress-end"]]: progressStatus === "end",
              [style["loading-progress-finish"]]: progressStatus === "finish",
            })}
          >
            <div
              ref={progressBarRef}
              className={classNames(style["loading-progress-bar"], {
                [style["loading-progress-bar-start"]]:
                  progressStatus === "start",
              })}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
