import React from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";

// style
import common from "assets/scss/common.module.scss";

const Countries = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [countries] = useRest("Country");

  const buildOption = () => {
    if (countries === null) return false;

    return countries.data.map((item, idx) => {
      return (
        <option key={idx} value={item.Country__}>
          {item.Name}
        </option>
      );
    });
  };
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div className={`${common["form-select"]}`}>
      <select
        className={`${common["input"]} ${common["input-select"]}`}
        onChange={handleChange}
        value={value}
      >
        <option value="">{t("purchase_pay_payment_country")}</option>
        {buildOption()}
      </select>
    </div>
  );
};

export default Countries;
