import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { Store } from "store/store";
import classNames from "classnames";
import { CardNumberElement } from "@stripe/react-stripe-js";

// component
import Toast, { error } from "components/toast/toast";
import Stripe from "components/stripe/stripe";
import CreateAccount from "./createAccount";
import CreateBilling from "./createBilling";
import ModalPurchaseComfirm from "components/modal/purchaseComfirm";
import ModalPurchaseSuccess from "components/modal/purchaseSucess";
import Loading from "components/loading/loading";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/order.module.scss";

// img
import cloud from "assets/images/img-cloud.svg";

// constant
import {
  CATALOG_PRODUCT_SMALL,
  CATALOG_PRODUCT_MEDIUM,
  CATALOG_PRODUCT_LARGE,
  CATALOG_PRODUCT_CUSTOM,
  VAT_INIT,
} from "store/reducer";

const PaymentMethod = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Store);
  const [user] = useRest("User:get");

  // process
  const [processEnabled, setProcessEmabled] = useState(false);
  const [processingLoad, setProcessingLoad] = useState(false);
  const [processComp, setProcessComp] = useState(false);

  // stripe
  const [stripeKey, setStripeKey] = useState(null);
  const [stripeElements, setStripeElements] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeEnabled, setStripeEnabled] = useState(false);

  // account creation
  const [accountEnabled, setAccountEnabled] = useState(false);
  const [accountValues, setAccountValues] = useState();

  // billing creation
  const [billing, setBilling] = useState(null);
  const [billingEnabled, setBillingEnabled] = useState(false);
  const [billingValues, setBillingValues] = useState();

  // method
  const [method, setMethod] = useState(null);

  // purchase
  const [purchase, setPurchase] = useState(false);

  // process emabled
  useEffect(() => {
    if (accountEnabled && billingEnabled && stripeEnabled) {
      setProcessEmabled(true);
    } else {
      setProcessEmabled(false);
    }
  }, [stripeEnabled, accountEnabled, billingEnabled]);

  // get StripeKey
  useEffect(() => {
    rest("Order/Payment:methodInfo", "POST", { method: "Stripe" }).then(
      (data) => {
        setStripeKey(data);
      }
    );
    rest("Order/Vat", "GET").then((data) => {
      dispatch({
        type: VAT_INIT,
        vat: data.data,
      });
    });
  }, []);

  // get billing
  useEffect(() => {
    if (user) {
      setAccountEnabled(true);
      if (user.data) {
        rest("User/@/Billing").then((d) => {
          setBilling(d.data[0]);
          if (d.data.length > 0) {
            setProcessEmabled(true);
          }
        });
      }
    } else {
      setBilling(null);
    }
  }, [user]);

  // get method
  useEffect(() => {
    if (billing !== null && billing !== void 0) {
      rest("User/Billing/" + billing.User_Billing__ + "/Method").then((d) => {
        setMethod(d.data[0]);
      });
    }
  }, [billing]);

  // pay
  const handlePay = () => {
    setProcessingLoad(true);

    const params = {
      User_Billing__: billing.User_Billing__,
      Catalog_Product__: state.selected.Catalog_Product__,
      Label: "vpnet",
      Size:
        state.selected.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM
          ? state.nbUnit
          : null,
    };
    return rest("Shell", "POST", params)
      .then(() => {
        setProcessComp(true);
      })
      .catch((response) => {
        error(response.message, false);
      });
  };

  // create Billing
  const createBillingAndPay = () => {
    let stripeToken = null;
    const cardElement = stripeElements.getElement(CardNumberElement);

    stripe
      .createToken(cardElement)
      .then(({ error, token }) => {
        if (error) throw new Error(error.message);

        stripeToken = token;

        return stripeToken;
      })
      .then(() => {
        return rest("User/@/Location", "POST", billingValues)
          .then((data) => {
            return data;
          })
          .catch((data) => {
            throw data;
          });
      })
      .then((location) => {
        return rest("User/@/Billing:create", "POST", {
          Label: "vpnet Subscription",
          User_Location__: location.data.User_Location__,
          cc_token: stripeToken.id,
          method: "Stripe",
        })
          .then((data) => {
            return data;
          })
          .catch((data) => {
            throw data;
          });
      })
      .then((billingData) => {
        const params = {
          User_Billing__: billingData.data.User_Billing__,
          Catalog_Product__: state.selected.Catalog_Product__,
          Label: "vpnet",
          Size:
            state.selected.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM
              ? state.nbUnit
              : null,
        };
        return rest("Shell", "POST", params)
          .then((data) => {
            return data;
          })
          .catch((response) => {
            error(response.message, false);
          });
      })
      .then(() => {
        // Create & Settle
        setProcessComp(true);
      })
      .catch((response) => {
        setProcessingLoad(false);
        error(response.message, false);
      });
  };

  // create Account to pay
  const handleCreationAndPay = () => {
    setProcessingLoad(true);

    if (user.data) {
      createBillingAndPay();
      return;
    }

    rest("User:register", "POST", accountValues)
      .then(() =>
        rest("User:login", "POST", {
          login: accountValues.Email,
          password: accountValues.Password,
        })
      )
      .then(() => {
        createBillingAndPay();
      })
      .catch((response) => {
        setProcessingLoad(false);
        error(response.message, false);
      });
  };

  // Calculated price
  const calPrice = (value) => {
    let num = value.display;
    if (state.selected.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM) {
      num = parseFloat(value.value);
      num = `$${(num * state.nbUnit * state.term).toFixed(2)}`;
    }
    return num;
  };

  if (!user) return false;

  return (
    <>
      <div
        className={`${common["wrapper"]} ${common["wrapper--white"]} ${style["order-create-wrapper"]}`}
      >
        <div
          className={`${common["wrapper-inner"]} ${style["order-create-inner"]}`}
        >
          <h2
            className={common["box-title"]}
            dangerouslySetInnerHTML={{ __html: t("purchase_pay_title") }}
          ></h2>
          <div
            className={classNames(style["order-create"], {
              [style["order-create--method"]]: billing && method,
            })}
          >
            {!user.data && (
              <CreateAccount
                setAccountEnabled={setAccountEnabled}
                setAccountValues={setAccountValues}
              />
            )}
            {!billing && (
              <CreateBilling
                setBillingEnabled={setBillingEnabled}
                setBillingValues={setBillingValues}
              />
            )}
            <div className={style["order-create-info"]}>
              {billing && method && (
                <dl className={style["order-create-method"]}>
                  <div className={style["order-create-method-item"]}>
                    <dt className={style["order-create-method-item-name"]}>
                      {t("purchase_pay_payment_method")}
                    </dt>
                    <dd className={style["order-create-method-item-body"]}>
                      {method.Name}
                    </dd>
                  </div>
                  <div className={style["order-create-method-item"]}>
                    <dt className={style["order-create-method-item-name"]}>
                      {t("purchase_pay_payment_expiration")}
                    </dt>
                    <dd className={style["order-create-method-item-body"]}>
                      {method.Expiration}
                    </dd>
                  </div>
                </dl>
              )}
              <dl className={style["order-create-info-price"]}>
                <div className={style["order-create-info-price-item"]}>
                  <dt className={style["order-create-info-price-item-name"]}>
                    {state.selected.Catalog_Product__ ===
                      CATALOG_PRODUCT_SMALL && t("product_small")}
                    {state.selected.Catalog_Product__ ===
                      CATALOG_PRODUCT_MEDIUM && t("product_medium")}
                    {state.selected.Catalog_Product__ ===
                      CATALOG_PRODUCT_LARGE && t("product_large")}
                    {state.selected.Catalog_Product__ ===
                      CATALOG_PRODUCT_CUSTOM && t("product_custom")}
                    {state.selected.name}
                  </dt>
                  <dd className={style["order-create-info-price-item-price"]}>
                    {calPrice(state.selected["Price.Price"])}{" "}
                    {t("purchase_pay_price_product_mo")}
                  </dd>
                </div>
                <div className={style["order-create-info-price-item"]}>
                  <dt className={style["order-create-info-price-item-name"]}>
                    {t("purchase_pay_price_term")}
                  </dt>
                  <dd className={style["order-create-info-price-item-price"]}>
                    {state.term}
                    {t("purchase_pay_price_month")}
                  </dd>
                </div>
                <div className={style["order-create-info-price-item"]}>
                  <dt className={style["order-create-info-price-item-name"]}>
                    {t("purchase_pay_price_tax")}
                  </dt>
                  <dd className={style["order-create-info-price-item-price"]}>
                    {calPrice(state.selected["Price.Price"].tax_only)}
                  </dd>
                </div>
                <div className={style["order-create-info-price-item"]}>
                  <dt className={style["order-create-info-price-item-name"]}>
                    {t("purchase_pay_price_total")}
                  </dt>
                  <dd className={style["order-create-info-price-item-total"]}>
                    {calPrice(state.selected["Price.Price"].tax)}
                  </dd>
                </div>
              </dl>
              {!billing && (
                <Stripe
                  stripeKey={stripeKey}
                  setStripeElements={setStripeElements}
                  setStripe={setStripe}
                  setStripeEnabled={setStripeEnabled}
                />
              )}
            </div>
          </div>
          <div
            className={classNames(style["order-create-btn-group"], {
              [style["order-create-btn-group--method"]]: billing && method,
            })}
          >
            <button
              className={`${common["btn"]} ${style["order-create-btn"]}`}
              onClick={() => {
                setPurchase(true);
              }}
              disabled={!processEnabled}
            >
              {t("purchase_pay_btn")}
            </button>
          </div>
        </div>
        <img className={style["order-create-cloud"]} src={cloud} alt="" />
      </div>
      <Toast duration={5000} />
      {processingLoad && (
        <Loading setLoad={setProcessingLoad} closeLoad={processComp} />
      )}
      {purchase && (
        <ModalPurchaseComfirm
          setModal={setPurchase}
          btnFunc={billing && method ? handlePay : handleCreationAndPay}
        />
      )}
      {processComp && <ModalPurchaseSuccess setModal={setProcessComp} />}
    </>
  );
};

export default PaymentMethod;
