import React from "react";
import { useHistory } from "react-router-dom";

// style
import common from "assets/scss/common.module.scss";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const TopicPath = ({ setDirection, path }) => {
  const history = useHistory();

  const clickTopic = (url) => {
    setDirection("toLeft");
    history.push(url);
  };

  const buildTopics = () => {
    return path.map((item, key) => {
      if (item.path !== void 0) {
        return (
          <li
            className={common["topic-path-item"]}
            onClick={() => {
              clickTopic(item.path);
            }}
            key={key}
          >
            {item.name}
            <FontAwesomeIcon icon={faChevronRight} />
          </li>
        );
      } else {
        return (
          <li
            className={`${common["topic-path-item"]} ${common["topic-path-item-active"]}`}
            key={key}
          >
            {item.name}
          </li>
        );
      }
    });
  };

  return (
    <>
      <ul className={common["topic-path"]}>{buildTopics()}</ul>
    </>
  );
};

export default TopicPath;
