import React, { useEffect, useRef } from "react";
import classNames from "classnames";

// style
import style from "assets/scss/modal.module.scss";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ setModal, children, close, setClose, id = "default" }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current === null) return false;

    const modalRefCurrent = modalRef.current;

    modalRefCurrent.addEventListener("animationend", (e) => {
      if (e.target.id !== modalRef.current.id) return false;

      if (close) {
        setModal(false);
      }
    });

    // cleanup
    return () => {
      modalRefCurrent.removeEventListener("animationend", () => {
        if (close) {
          setModal(false);
        }
      });
    };
  }, [modalRef, close, setModal]);

  return (
    <>
      <div
        className={classNames(style["modal-wrapper"], {
          [style["modal-wrapper-close"]]: close,
        })}
        id={id}
        ref={modalRef}
      >
        <div className={style["modal"]}>
          {children}
          <button
            onClick={() => {
              setClose(true);
            }}
            className={style["modal-close"]}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className={style["modal-layer"]}></div>
      </div>
    </>
  );
};

export default Modal;
