import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import _ from "lodash";
import { Element } from "react-scroll";

// style
import common from "assets/scss/common.module.scss";
import index from "assets/scss/index.module.scss";

//img
import bgSolutions from "assets/images/bg-solutions.png";
import planSmall from "assets/images/img-plan-small.svg";
import planMedium from "assets/images/img-plan-medium.svg";
import planLarge from "assets/images/img-plan-large.svg";
import planCustom from "assets/images/img-plan-custom.svg";
import cloud from "assets/images/img-cloud.svg";

// constant
import {
  CATALOG_PRODUCT_SMALL,
  CATALOG_PRODUCT_MEDIUM,
  CATALOG_PRODUCT_LARGE,
  CATALOG_PRODUCT_CUSTOM,
} from "store/reducer";

const Solutions = ({ setDirection }) => {
  const { t } = useTranslation();

  const [plans] = useRest("Catalog/Product:search", "Post");
  const [small, setSmall] = useState(null);
  const [medium, setMedium] = useState(null);
  const [large, setLarge] = useState(null);
  const [custom, setCustom] = useState(null);

  useEffect(() => {
    if (!plans) return false;

    setSmall(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_SMALL])
    );
    setMedium(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_MEDIUM])
    );
    setLarge(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_LARGE])
    );

    setCustom(
      _.find(plans.data.data, ["Catalog_Product__", CATALOG_PRODUCT_CUSTOM])
    );
  }, [plans]);

  if (!plans) return false;

  return (
    <Element name="solution">
      <div
        className={`${common["wrapper"]} ${common["wrapper--cloud"]} ${index["solutions-wrapper"]}`}
        style={{ backgroundImage: `url(${bgSolutions})` }}
      >
        <div className={`${common["wrapper-inner"]} ${index["solutions"]}`}>
          <div className={index["solutions-text-group"]}>
            <h2 className={index["solutions-title"]}>{t("solutions_title")}</h2>
            <p className={index["solutions-info"]}>{t("solutions_text")}</p>
          </div>
          <div className={index["solutions-plans"]}>
            {small && (
              <div className={index["solutions-plans-item"]}>
                <Link
                  to={`product/small`}
                  onClick={() => {
                    setDirection("toRight");
                  }}
                  className={index["solutions-plans-item-inner"]}
                >
                  <div className={index["solutions-plans-item-img"]}>
                    <img src={planSmall} alt="Small" />
                  </div>
                  <h3 className={index["solutions-plans-item-name"]}>
                    {t("product_small")}
                  </h3>
                  <p
                    className={`${index["solutions-plans-item-desc"]} ${
                      index[`solutions-plans-item-desc-${small.name}`]
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: t("product_small_desc"),
                    }}
                  />
                  <div className={index["solutions-plans-item-price"]}>
                    <span>
                      {small["Price.Price"].display} /{" "}
                      {t("solutions_price_unit")}
                    </span>
                  </div>
                </Link>
              </div>
            )}

            {medium && (
              <div className={index["solutions-plans-item"]}>
                <Link
                  to={`product/medium`}
                  onClick={() => {
                    setDirection("toRight");
                  }}
                  className={index["solutions-plans-item-inner"]}
                >
                  <div className={index["solutions-plans-item-img"]}>
                    <img src={planMedium} alt="Medium" />
                  </div>
                  <h3 className={index["solutions-plans-item-name"]}>
                    {t("product_medium")}
                  </h3>
                  <p
                    className={`${index["solutions-plans-item-desc"]} ${
                      index[`solutions-plans-item-desc-${medium.name}`]
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: t("product_medium_desc"),
                    }}
                  />
                  <div className={index["solutions-plans-item-price"]}>
                    <span>
                      {medium["Price.Price"].display} /{" "}
                      {t("solutions_price_unit")}
                    </span>
                  </div>
                </Link>
              </div>
            )}

            {large && (
              <div className={index["solutions-plans-item"]}>
                <Link
                  to={`product/large`}
                  onClick={() => {
                    setDirection("toRight");
                  }}
                  className={index["solutions-plans-item-inner"]}
                >
                  <div className={index["solutions-plans-item-img"]}>
                    <img src={planLarge} alt="Large" />
                  </div>
                  <h3 className={index["solutions-plans-item-name"]}>
                    {t("product_large")}
                  </h3>
                  <p
                    className={`${index["solutions-plans-item-desc"]} ${
                      index[`solutions-plans-item-desc-${large.name}`]
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: t("product_large_desc"),
                    }}
                  />
                  <div className={index["solutions-plans-item-price"]}>
                    <span>
                      {large["Price.Price"].display} /{" "}
                      {t("solutions_price_unit")}
                    </span>
                  </div>
                </Link>
              </div>
            )}

            {custom && (
              <div className={index["solutions-plans-item"]}>
                <Link
                  to={`product/custom`}
                  onClick={() => {
                    setDirection("toRight");
                  }}
                  className={index["solutions-plans-item-inner"]}
                >
                  <div className={index["solutions-plans-item-img"]}>
                    <img src={planCustom} alt="Custom" />
                  </div>
                  <h3 className={index["solutions-plans-item-name"]}>
                    {t("product_custom")}
                  </h3>
                  <p
                    className={`${index["solutions-plans-item-desc"]} ${
                      index[`solutions-plans-item-desc-${custom.name}`]
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: t("product_custom_desc"),
                    }}
                  />
                  <div className={index["solutions-plans-item-price"]}>
                    <span>..... / {t("solutions_price_unit")}</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <p
            className={index["solutions-title-vertical"]}
            dangerouslySetInnerHTML={{ __html: t("solutions_title_vertical") }}
          />
        </div>

        <img
          className={index["solutions-cloud"]}
          src={cloud}
          alt={t("solutions_title")}
        />
      </div>
    </Element>
  );
};

export default Solutions;
