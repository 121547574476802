import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

// style
import common from "assets/scss/common.module.scss";

// img
import defaultUser from "assets/images/icon-default-avatar.svg";
import iconLoginSp from "assets/images/icon-login-sp.svg";

export default function LoginMenu({
  setDirection,
  setMenuToggle,
  user,
  type = "default",
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const toLogin = () => {
    setDirection("toTop");
    setMenuToggle(false);
    history.push("/login");
  };

  const buildUserImg = () => {
    if (user.data.Profile.Drive_Item__ !== null) {
      return (
        <img
          className={common["header-info-user-icon"]}
          src={user.data.Profile.Drive_Item.Media_Image.Url}
          alt={user.data.Profile.Display_Name}
        />
      );
    } else {
      return (
        <img
          className={common["header-info-user-icon-default"]}
          src={defaultUser}
          alt={user.data.Profile.Display_Name}
        />
      );
    }
  };

  const buildLoginMenu = () => {
    if (user === null) return false;

    if (user.data === null) {
      switch (type) {
        case "sp":
          return (
            <button
              className={common["header-menu--sp-login"]}
              onClick={() => {
                toLogin();
              }}
            >
              <img src={iconLoginSp} alt={t("head_login")} />
            </button>
          );
        default:
          return (
            <button
              className={`${common["btn"]} ${common["header-login"]}`}
              onClick={() => {
                toLogin();
              }}
            >
              {t("head_login")}
            </button>
          );
      }
    } else {
      return (
        <div
          className={classNames({
            [common["header-info"]]: type !== "sp",
            [common["header-info--sp"]]: type === "sp",
          })}
        >
          <a
            href="https://c.vp.net/"
            className={`${common["btn"]} ${common["header-info-btn"]}`}
          >
            {t("head_myvps")}
          </a>
          <a
            className={common["header-info-user"]}
            href="https://c.vp.net/account"
          >
            {buildUserImg()}
          </a>
        </div>
      );
    }
  };

  return <>{buildLoginMenu()}</>;
}
