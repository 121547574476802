import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import DateUtil from "components/common/data/DateUtil";
import { Link, useParams } from "react-router-dom";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/blog.module.scss";

// component
import Helmet from "components/Helmet/Helmet";
import Header from "components/layout/header";

const BlogArticle = ({ setDirection }) => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const IMG_VARIATION = "format=jpeg&strip&scale_crop=723x723";
  const [article] = useRest(
    `Content/Cms/ctcm-mpygge-2mcj-czdh-svvn-d33obke4:loadSlug`,
    {
      slug: slug,
      image_variation: IMG_VARIATION,
    },
    true
  );

  const path = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Blog",
      path: "/blog",
    },
    {
      name: article !== null ? article.data.content_cms_entry_data.Title : "",
    },
  ];

  if (article === null) return false;

  return (
    <>
      <Helmet>
        <title>
          {article.data.content_cms_entry_data.Title}| {t("head_title")}
        </title>
      </Helmet>
      <Header setDirection={setDirection} type="lower" path={path} />
      <div className={style["blog-article-wrapper"]}>
        <Link
          to="/blog"
          onClick={() => {
            setDirection("toLeft");
          }}
          className={`${common["scroll-btn"]} ${common["scroll-btn--left"]} ${style["blog-article-back"]}`}
        ></Link>
        <div
          className={style["blog-article-info"]}
          style={{
            backgroundImage: `url(${
              article.data.content_cms_entry_data.Drive_Item !== null
                ? article.data.content_cms_entry_data.Drive_Item.Media_Image[
                    IMG_VARIATION
                  ]
                : null
            })`,
          }}
        >
          <h1 className={style["blog-article-info-title"]}>
            {article.data.content_cms_entry_data.Title}
          </h1>
          <span className={style["blog-article-info-date"]}>
            <DateUtil
              klbDateObj={article.data.content_cms_entry_data.Created}
              format="MM.DD.YY"
            />
          </span>
        </div>
        <div
          className={style["blog-article-contents"]}
          dangerouslySetInnerHTML={{
            __html: article.data.content_cms_entry_data.Contents,
          }}
        />
      </div>
    </>
  );
};

export default BlogArticle;
