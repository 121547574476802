import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// component
import Modal from "./modal";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/modal.module.scss";

// img
import Bg from "assets/images/bg-modal.png";
import imgPurchase from "assets/images/img-purchase-confirm.png";

const ModalPurchaseComfirm = ({ btnFunc, setModal }) => {
  const { t } = useTranslation();
  const [close, setClose] = useState(false);

  return (
    <Modal setModal={setModal} close={close} setClose={setClose} id="confirm">
      <div className={style["modal-header"]}>
        <img className={style["modal-header-bg"]} src={Bg} alt="" />
        <img
          className={style["modal-header-img"]}
          src={imgPurchase}
          alt={t("purchase_modal_confirm_title")}
        />
        <h2
          className={style["modal-header-title"]}
          dangerouslySetInnerHTML={{
            __html: t("purchase_modal_confirm_title"),
          }}
        />
      </div>
      <div className={style["modal-text"]}>
        <p
          dangerouslySetInnerHTML={{ __html: t("purchase_modal_confirm_text") }}
        ></p>
      </div>
      <div className={`${common["btn-group"]} ${style["modal-btns"]}`}>
        <button
          className={`${common["btn"]} ${common["btn--glay"]}`}
          onClick={() => {
            setClose(true);
          }}
        >
          {t("btn_cancel")}
        </button>
        <button
          className={common["btn"]}
          onClick={() => {
            setClose(true);
            btnFunc();
          }}
        >
          {t("btn_buy")}
        </button>
      </div>
    </Modal>
  );
};

export default ModalPurchaseComfirm;
