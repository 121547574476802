import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// component
import Modal from "./modal";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/modal.module.scss";

// img
import Bg from "assets/images/bg-modal.png";
import imgSucsess from "assets/images/img-purchase-sucsess.png";

const ModalPurchaseSuccess = ({ setModal }) => {
  const { t } = useTranslation();
  const [close, setClose] = useState(false);
  const history = useHistory();

  return (
    <Modal setModal={setModal} close={close} setClose={setClose}>
      <div className={style["modal-header"]}>
        <img className={style["modal-header-bg"]} src={Bg} alt="" />
        <img
          className={`${style["modal-header-img"]} ${style["modal-header-img-success"]}`}
          src={imgSucsess}
          alt={t("purchase_modal_success_title")}
        />
        <h2
          className={`${style["modal-header-title"]} ${style["modal-header-title-success"]}`}
          dangerouslySetInnerHTML={{
            __html: t("purchase_modal_success_title"),
          }}
        />
      </div>
      <div className={style["modal-text"]}>
        <p
          dangerouslySetInnerHTML={{ __html: t("purchase_modal_success_text") }}
        ></p>
      </div>
      <div className={`${common["btn-group"]} ${style["modal-btns"]}`}>
        <button
          className={common["btn"]}
          onClick={() => {
            history.push("/");
          }}
        >
          {t("btn_back_home")}
        </button>
      </div>
    </Modal>
  );
};

export default ModalPurchaseSuccess;
