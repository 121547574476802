import React from "react";
import i18next from "i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = ({ duration = 2000, position = "top-center" }) => {
  return (
    <>
      <ToastContainer
        position={position}
        autoClose={duration}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};
export default Toast;

const translationHandler = (msg, translate) => {
  if (translate) {
    return i18next.t(msg);
  } else {
    return msg;
  }
};

const toastTypeHandller = (msg, translate = true, type = "default") => {
  switch (type) {
    case "error":
      toast.error(translationHandler(msg, translate));
      break;
    case "success":
      toast.success(translationHandler(msg, translate));
      break;
    case "warning":
      toast.warning(translationHandler(msg, translate));
      break;
    case "info":
      toast.info(translationHandler(msg, translate));
      break;
    case "dark":
      toast.dark(translationHandler(msg, translate));
      break;
    default:
      toast(translationHandler(msg, translate));
      break;
  }
};

export const error = (msg, translate = true) => {
  toastTypeHandller(msg, translate, "error");
};

export const success = (msg, translate = true) => {
  toastTypeHandller(msg, translate, "success");
};

export const warning = (msg, translate = true) => {
  toastTypeHandller(msg, translate, "warning");
};

export const info = (msg, translate = true) => {
  toastTypeHandller(msg, translate, "info");
};

export const toastDark = (msg, translate = true) => {
  toastTypeHandller(msg, translate, "dark");
};

export const toastDefault = (msg, translate = true) => {
  toastTypeHandller(msg, translate);
};
