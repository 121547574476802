import React from "react";
import { useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import Helmet from "components/Helmet/Helmet";
import Header from "components/layout/header";
import Hero from "./section/hero";
import Buy from "./section/buy";
import Features from "./section/features";
import Use from "./section/use";

// constant
import {
  CATALOG_PRODUCT_SMALL,
  CATALOG_PRODUCT_MEDIUM,
  CATALOG_PRODUCT_LARGE,
  CATALOG_PRODUCT_CUSTOM,
} from "store/reducer";

const Product = ({ setDirection }) => {
  const { productId } = useParams();

  const catalogProductId = (productId) => {
    switch (productId) {
      case "small":
        return CATALOG_PRODUCT_SMALL;
      case "medium":
        return CATALOG_PRODUCT_MEDIUM;
      case "large":
        return CATALOG_PRODUCT_LARGE;
      case "custom":
        return CATALOG_PRODUCT_CUSTOM;
      default:
        return "a";
    }
  };
  const [plan] = useRest(`Catalog/Product/${catalogProductId(productId)}`);

  const path = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Solutions",
      path: "/#solution",
    },
    {
      name: productId.charAt(0).toUpperCase() + productId.slice(1),
    },
  ];

  if (!plan) return false;

  return (
    <>
      <Helmet>
        <title>products | vp net</title>
      </Helmet>
      <Header setDirection={setDirection} type="lower" path={path} />
      <Hero plan={plan.data} />
      <Buy plan={plan.data} setDirection={setDirection} />
      <Features />
      <Use />
    </>
  );
};

export default Product;
