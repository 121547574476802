import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "store/store";
import { useHistory } from "react-router-dom";

// component
import Spec from "components/spec/spec";

// style
import common from "assets/scss/common.module.scss";
import style from "assets/scss/product.module.scss";

// constant
import { CATALOG_PRODUCT_CUSTOM } from "store/reducer";

const Buy = ({ plan, setDirection }) => {
  const { state, dispatch } = useContext(Store);
  const { t } = useTranslation();
  const history = useHistory();

  const toJoin = () => {
    setDirection("toBottom");

    dispatch({
      type: "SELECTED_DONE",
      selected: plan,
    });

    history.push("/join");
  };

  const buildPrice = () => {
    let price = parseFloat(plan["Price.Price"].value);

    if (plan.Catalog_Product__ === CATALOG_PRODUCT_CUSTOM) {
      price *= state.nbUnit;
    }

    return `$${price.toFixed(2)}`;
  };

  return (
    <div className={`${common["wrapper"]} ${style["product-buy-wrapper"]}`}>
      <div className={`${common["wrapper-inner"]} ${style["product-buy"]}`}>
        <div className={style["product-buy-spec"]}>
          <Spec selectedPlan={plan} />
        </div>

        <div className={style["product-buy-group"]}>
          <p className={style["product-buy-group-text"]}>
            {t("product_buy_text")}
          </p>
          <div className={style["product-buy-group-price"]}>
            {buildPrice()} / MO
          </div>
          <div className={style["product-buy-group-purchase"]}>
            <button
              className={style["product-buy-group-purchase-btn"]}
              onClick={() => {
                toJoin();
              }}
            >
              {t("product_buy_btn")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
