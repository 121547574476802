import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

// style
import style from "assets/scss/loading.module.scss";

//img
import loading from "assets/images/icon-loading.svg";

const LoginLoading = ({ type, updateValue = null }) => {
  const loadingWrapper = useRef(null);
  const [animationComp, setAnimationComp] = useState(false);

  const animationEventHandler = useCallback((e) => {
    if (e.target !== loadingWrapper.current) return false;

    setAnimationComp(true);
  }, []);

  useEffect(() => {
    const loginWrapperCurrent = loadingWrapper.current;

    // init
    if (updateValue !== null) {
      setAnimationComp(false);
    }

    loginWrapperCurrent.addEventListener("animationend", animationEventHandler);

    // init
    return () => {
      if (loginWrapperCurrent !== null) {
        loginWrapperCurrent.removeEventListener(
          "animationend",
          animationEventHandler
        );
      }
    };
  }, [updateValue, animationEventHandler]);

  return (
    <>
      {!animationComp && (
        <div
          ref={loadingWrapper}
          className={classNames(style["login-loading"], {
            [style["login-loading--fade-out"]]: type === "fade",
            [style["login-loading--fade-comp"]]: animationComp,
          })}
        >
          <img className={style["login-loading-logo"]} src={loading} alt="" />
        </div>
      )}
    </>
  );
};

export default LoginLoading;
