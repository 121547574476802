import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Element } from "react-scroll";

// style
import common from "assets/scss/common.module.scss";
import index from "assets/scss/index.module.scss";

//img
import regionMap from "assets/images/bg-region-map.svg";
import regionRegions from "assets/images/bg-region-regions.png";
import regionLocation from "assets/images/bg-region-location.png";

const Regions = () => {
  const { t } = useTranslation();

  return (
    <Element name="region">
      <div
        className={`${common["wrapper"]} ${common["wrapper--glay"]} ${index["region-wrapper"]}`}
      >
        <div className={`${common["wrapper-inner"]} ${index["region"]}`}>
          <div className={index["region-title-group"]}>
            <h2 className={index["region-title"]}>{t("region_title")}</h2>
            <p className={index["region-text"]}>{t("region_text")}</p>
          </div>
          <ul className={index["region-icons"]}>
            <li className={index["region-icons-locations"]}>
              <span>
                <img src={regionLocation} alt="" />
              </span>
              {t("region_locations")}
            </li>
            <li className={index["region-icons-regions"]}>
              <span>
                <img src={regionRegions} alt="" />
              </span>
              {t("region_regions")}
            </li>
          </ul>
          <div className={index["region-map"]}>
            <img src={regionMap} alt="" />
            <div
              className={`${index["region-map-regions-tokyo"]} ${index["region-map-regions"]}`}
            >
              <img
                className={index["region-map-img-regions"]}
                src={regionRegions}
                alt={t("region_location_tk")}
              />
              <img
                data-tip={`<strong>${t("region_location_tk")}</strong><br/>${t(
                  "region_location_tk_zone"
                )}`}
                className={index["region-map-img-location"]}
                src={regionLocation}
                alt={t("region_location_tk")}
              />
            </div>
            <div
              className={`${index["region-map-regions-la"]} ${index["region-map-regions"]}`}
            >
              <img
                className={index["region-map-img-regions"]}
                src={regionRegions}
                alt={t("region_location_la")}
              />
              <img
                data-tip={`<strong>${t("region_location_la")}</strong><br/>${t(
                  "region_location_la_zone"
                )}`}
                className={index["region-map-img-location"]}
                src={regionLocation}
                alt={t("region_location_la")}
              />
            </div>
            <div
              className={`${index["region-map-regions-am"]} ${index["region-map-regions"]}`}
            >
              <img
                className={index["region-map-img-regions"]}
                src={regionRegions}
                alt={t("region_location_am")}
              />
              <img
                data-tip={`<strong>${t("region_location_am")}</strong><br/>${t(
                  "region_location_am_zone"
                )}`}
                className={index["region-map-img-location"]}
                src={regionLocation}
                alt={t("region_location_am")}
              />
            </div>
            <ReactTooltip
              backgroundColor="#8BC53F"
              place="bottom"
              effect="solid"
              arrowColor="transparent"
              html={true}
              className={common["tooltip"]}
            />
          </div>
          <div className={index["region-location"]}>
            <div className={index["region-location-inner"]}>
              <dl className={index["region-location-item"]}>
                <dt>{t("region_location_la")}</dt>
                <dd>{t("region_location_la_zone")}</dd>
              </dl>
            </div>
            <div className={index["region-location-inner"]}>
              <dl className={index["region-location-item"]}>
                <dt>{t("region_location_am")}</dt>
                <dd
                  dangerouslySetInnerHTML={{
                    __html: t("region_location_am_zone"),
                  }}
                />
              </dl>
              <dl className={index["region-location-item"]}>
                <dt>{t("region_location_tk")}</dt>
                <dd
                  dangerouslySetInnerHTML={{
                    __html: t("region_location_tk_zone"),
                  }}
                />
              </dl>
            </div>
          </div>
          <p
            className={index["region-title-vertical"]}
            dangerouslySetInnerHTML={{ __html: t("region_title_vertical") }}
          />
        </div>
      </div>
    </Element>
  );
};

export default Regions;
