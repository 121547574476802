import React, { createContext, useReducer } from "react";
import reducer from "./reducer";

const initialState = {
  selected: null,
  term: 1,
  nbUnit: 8,
  vat: null,
};

export const Store = createContext();

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};
