export const CATALOG_PRODUCT_SMALL = "cpr-ivzbuk-l22v-h6jh-7dad-a5tsb2c4";
export const CATALOG_PRODUCT_MEDIUM = "cpr-aphk4i-waij-gglo-re5v-nwcizzgy";
export const CATALOG_PRODUCT_LARGE = "cpr-qsm5wu-6zg5-cgpe-7ukh-ewdg4kza";
export const CATALOG_PRODUCT_CUSTOM = "cpr-4ergky-fb5r-e6xo-xq77-uqg23tni";

export const SELECTED_DONE = "SELECTED_DONE";
export const CHANGHE_TERM = "CHANGHE_TERM";
export const UNIT_CHANGE = "UNIT_CHANGE";
export const VAT_INIT = "VAT_INIT";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_DONE:
      return {
        ...state,
        selected: action.selected,
      };
    case CHANGHE_TERM:
      return {
        ...state,
        term: action.term,
      };
    case UNIT_CHANGE:
      return {
        ...state,
        nbUnit: action.nbUnit,
      };
    case VAT_INIT:
      return {
        ...state,
        vat: action.vat,
      };
    default:
      return state;
  }
};

export default reducer;
